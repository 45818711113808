import { Contract } from "@ethersproject/contracts";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";
import { Web3Provider, JsonRpcSigner } from "@ethersproject/providers";

export function isAddress(value: any): string | false {
    try {
        return getAddress(value);
    } catch {
        return false;
    }
}

// account is optional
export function getContract(
    address: string,
    ABI: any,
    library: Web3Provider,
    account?: string
): Contract {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`);
    }

    return new Contract(
        address,
        ABI,
        getProviderOrSigner(library, account) as any
    );
}

// account is not optional
export function getSigner(
    library: Web3Provider,
    account: string
): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked();
}

// account is optional
export  function getProviderOrSigner(
    library: Web3Provider,
    account?: string
): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library;
}

export const signMessage = async (signer, message: string) => {
    try {
        if (!window.ethereum) {
            alert("MetaMask is not installed!");
            return;
        }
        const signedMessage = await signer.signMessage(message);
        return signedMessage;
    } catch (error) {
        console.error("Signing error:", error);
    }
};
