import { Button, Form, Modal } from 'react-bootstrap';
import './index.scss';

export type LoadingModalProps = {
	show: boolean;
	onClose: () => void;
};
const LoadingModal = ({ show, onClose }: LoadingModalProps) => {
	return (
		<Modal
			scrollable
			centered
			show={show}
			backdrop="static"
			keyboard={false}
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Loading</Modal.Title>
			</Modal.Header>
			<Modal.Body></Modal.Body>
			<Modal.Footer>
				<Form.Text className="opacity-25">
					Tip: Coin data cannot be changed after creation
				</Form.Text>
				<Button
					className="w-100"
					variant="warning"
					size="lg"
					onClick={() => {}}
				>
					Create coin
				</Button>
				<Form.Text>
					When your coin completes its bonding curve you receive 0.5 SOL
				</Form.Text>
			</Modal.Footer>
		</Modal>
	);
};
export default LoadingModal;
