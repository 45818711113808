import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOnBlockUpdated } from '../hooks/useOnBlockUpdated';
import useIsWindowVisible from '../hooks/useIsWindowVisible';
import useDebounce from '../hooks/useDebounce';
import { useWeb3React } from '@web3-react/core';
import { SET_ETH_BALANCE_SUCCESS } from '../redux/actions/wallet';
import { ETH_SEPOLIA_CHAIN_ID } from './constants';

export const ApplicationUpdater = () => {
	const { chainId, account, provider } = useWeb3React();
	const windowVisible = useIsWindowVisible();
	const dispatch = useDispatch();

	const [blockNumber, setBlockNumber] = useState(0);
	const [state, setState] = useState<{
		chainId: number | undefined;
		balance: number | null;
	}>({
		chainId,
		balance: null
	});

	useOnBlockUpdated((blockNumber: number) => {
		// console.log('blockNumber', blockNumber);
		setBlockNumber(blockNumber);
	});

	const getBalanceCallback = useCallback(
		(balance: number) => {
			setState((s: any) => {
				if (chainId === s.chainId) {
					if (typeof s.balance !== 'number') return { chainId, balance };
					return { chainId, balance: Math.max(balance, s.balance) };
				}
				return s;
			});
		},
		[chainId, setState]
	);

	const block = useDebounce(blockNumber, 1000);

	useEffect(() => {
		if (
			!chainId ||
			chainId !== ETH_SEPOLIA_CHAIN_ID ||
			!account ||
			!windowVisible ||
			!block ||
			!provider
		)
			return undefined;
		setState({ chainId, balance: null });
		provider
			.getBalance(account)
			.then((data: any) => {
				console.log('dajhfshagfsghas', data);
				dispatch({
					type: SET_ETH_BALANCE_SUCCESS,
					payload: data.toString()
				});
			})
			.catch((error: any) =>
				console.error(
					`Failed to get block number for chainId: ${chainId}`,
					error
				)
			);
	}, [chainId, provider, getBalanceCallback, windowVisible, block, account]);

	// //update USDC balance
	// const busdContract = useTokenContract(ENV_CONFIGS.USDC_ADDRESS);
	// useEffect(() => {
	//     if (!chainId || chainId !== AVAX_TESTNET_CHAIN_ID || !account || !windowVisible || !block || !provider)
	//         return undefined;
	//     try {
	//         if (busdContract) {
	//             busdContract.balanceOf(account).then((data: any) => {
	//                 console.log('bsdc bal', data?.toString());
	//                 dispatch({
	//                     type: SET_USDC_BALANCE_SUCCESS,
	//                     payload: data.toString(),
	//                 });
	//             });
	//         }
	//     } catch (error) {
	//         console.log('asgajshasjas');
	//     }
	// }, [provider, chainId, block, busdContract, account]);

	// //update EKL balance
	// const eklContract = useTokenContract(ENV_CONFIGS.EKL_ADDRESS);
	// useEffect(() => {
	//     if (!chainId || chainId !== AVAX_TESTNET_CHAIN_ID || !account || !windowVisible || !block || !provider)
	//         return undefined;
	//     try {
	//         if (eklContract) {
	//             eklContract.balanceOf(account).then((data: any) => {
	//                 console.log('elk bal', data?.toString());
	//                 dispatch({
	//                     type: SET_EKL_BALANCE_SUCCESS,
	//                     payload: data.toString(),
	//                 });
	//             });
	//         }
	//     } catch (error) {
	//         console.log('asgajshasjas');
	//     }
	// }, [provider, chainId, block, eklContract, account]);

	return null;
};
