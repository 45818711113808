import { Button } from 'react-bootstrap';
import './index.scss';

export type ReferralCTACardProps = {
	tutorialLink: string;
	connectTGLink: string;
};
const ReferralCTACard = ({
	tutorialLink,
	connectTGLink
}: ReferralCTACardProps) => {
	return (
		<div className="mf-referral-cta-card">
			<h3>
				Upgrade 🚀
				<br />
				Referral Code
			</h3>
			<p>
				GMGN now supports a unified invitation code. One reflink for all chains.
				Please log in to Telegram to view it.
			</p>
			<ul>
				<li>
					Please use the Telegram invitation code for new invites. Because your
					old referral code SL0QgtEpy will no longer accept new invitees.
				</li>
				<li>
					Commission rewards from previously invited users will still be
					retained and can be withdrawn.
				</li>
			</ul>
			<div className="d-flex align-items-center gap-2 my-3">
				<Button
					as="a"
					href={tutorialLink}
					target="_blank"
					variant="light"
					size="sm"
					className="w-100 py-2"
				>
					<span>View Tutorial</span>
				</Button>
				<Button
					as="a"
					href={connectTGLink}
					target="_blank"
					variant="warning"
					size="sm"
					className="w-100 py-2"
				>
					<img
						style={{ filter: 'invert(1)' }}
						src="/assets/images/icons/icon-telegram.svg"
						alt=""
					/>
					<span className="ms-1">Connect TG</span>
				</Button>
			</div>
			<h5>Invited Friends(0)</h5>
			<div>You have not yet invited anyone.</div>
		</div>
	);
};
export default ReferralCTACard;
