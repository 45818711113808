import axios from 'axios';
import { ENDPOINTS } from '../../constants';
import { request } from '../../libs/client';

export const fetchListNftInMarket = () => {
	return request(ENDPOINTS.LIST_NFT_MARKET, { method: 'GET' });
};

export const fetchNftDetail = ({ collectionAddress, nftId }: any) => {
	return request(`${ENDPOINTS.NFT_DETAIL}/${collectionAddress}/${nftId}`, {
		method: 'GET'
	});
};

export const fetchUserNft = (account: string) => {
	return request(`${ENDPOINTS.NFT_OWNER}/${account}`, { method: 'GET' });
};

export const fetchNFTInCollection = (collectionAddress: string) => {
	return request(`${ENDPOINTS.NFT_COLLECTION}/${collectionAddress}`, {
		method: 'GET'
	});
};

export const executeUserLogin = (
	messageBytes: string,
	signature: string,
	wallet: string,
	referral: string,
	type: string = 'evm'
) => {
	return request(`${ENDPOINTS.LOGIN}`, {
		method: 'POST',
		body: {
			dataSigned: {
				messageBytes,
				signature
			},
			wallet,
			referral,
			type
		}
	});
};

export const getUserInfo = () => {
	return request(`${ENDPOINTS.USER_INFO}`, {
		method: 'GET'
	});
};

export const updateUserReferrer = (ref: string) => {
	return request(`${ENDPOINTS.USER_REF}`, {
		method: 'PUT',
		body: {
			ref
		}
	});
};
