import { ValueFormatterParams, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Dropdown,
	FormControl,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ButtonTabs, { IButtonTab } from '../components/button-tabs';
import MemeCard from '../components/meme-card';
import MemeSimpCard from '../components/meme-simp-card';
import { APP_CONFIGS, PAGE_URLS } from '../constants';
import {
	useHotToken,
	useMyToken,
	useNewToken,
	useTopToken
} from '../hooks/useState';
import { getFullDisplayBalance } from '../utils/number';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
	GET_HOT_LIST_TOKEN_REQUEST,
	GET_NEW_TOKEN_REQUEST,
	GET_TOP_TOKEN_REQUEST
} from '../redux/actions/user';
// import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';

const DashboardPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const newTokens = useNewToken();
	const hotTokens = useHotToken();
	const topTokens = useTopToken();
	const myTokens = useMyToken();

	const TABS: IButtonTab[] = useMemo(
		() => [
			{ key: 'pump', text: 'Pump' },
			{ key: 'new-creation', text: 'New' },
			{ key: 'hot', text: 'Hot' },
			{ key: 'top', text: 'Top' },
			{ key: 'mine', text: 'Mine' }
		],
		[]
	);

	useEffect(() => {
		dispatch({
			type: GET_HOT_LIST_TOKEN_REQUEST
		});
		dispatch({
			type: GET_TOP_TOKEN_REQUEST
		});
		dispatch({
			type: GET_NEW_TOKEN_REQUEST
		});
	}, [dispatch]);

	useEffect(() => {
		// const chainFromUrl = searchParams.get(APP_CONFIGS.CHAIN_QUERY_PARAM);
		// if (!chainFromUrl) {
		// 	navigate(
		// 		`${PAGE_URLS.MEME}?${APP_CONFIGS.CHAIN_QUERY_PARAM}=${APP_CONFIGS.CHAIN_DEFAULT}`,
		// 		{ replace: true }
		// 	);
		// }
		// const tabFromUrl = searchParams.get('tab') || '';
		// const tabIndex =
		// 	TABS.findIndex(t => t.key === tabFromUrl) !== -1
		// 		? tabFromUrl
		// 		: TABS[0].key;
		// setActiveTabkey(tabIndex);
	}, [TABS, navigate, searchParams]);

	const [activeTabKey, setActiveTabkey] = useState(TABS[0].key);
	const onTabChange = (tabKey: string) => {
		setActiveTabkey(tabKey);
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set('tab', tabKey);
		navigate(
			{
				search: searchParams.toString()
			},
			{ replace: true }
		);
	};

	interface IData {
		token: any;
		status: number;
		age: number;
		cost1?: number;
		cost2?: number;
		holders: number;
		txs: number;
		vol: number;
		mktCap: number;
		last: number;
	}

	// Column Definitions: Defines the columns to be displayed.
	const COLUMNS: any[] = [
		{
			field: 'token',
			headerName: 'Token',
			pinned: 'left',
			minWidth: 225,
			flex: 1,
			cellRenderer: (data: any) => <MemeSimpCard data={data.data} />,
			cellStyle: {
				textAlign: 'left'
			}
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 110,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			cellDataType: 'number',
			valueFormatter: (params: ValueFormatterParams<IData, number>) =>
				`${params.value}%`,
			filter: 'agNumberColumnFilter',
			cellStyle: {
				color: '#fff',
				fontWeight: 500
			}
		},
		{
			field: 'createdAt',
			headerName: 'Age',
			minWidth: 110,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: ICellRendererParams<IData, number>) => (
				<OverlayTrigger
					placement="bottom"
					overlay={
						<Tooltip>{new Date(params?.value).toLocaleString()}</Tooltip>
					}
				>
					<span className="green">
						{moment(params?.value).fromNow().replace('ago', '')}{' '}
					</span>
				</OverlayTrigger>
			)
		},
		{
			field: 'cost1',
			headerName: '👑1/2 Cost',
			minWidth: 130,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: ICellRendererParams<IData, number>) =>
				params.value ? (
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>12/02 06:16:21</Tooltip>}
					>
						<span className="green">{params.value}m </span>
					</OverlayTrigger>
				) : (
					'-'
				)
		},
		{
			field: 'cost2',
			headerName: '🚀2/2 Cost',
			minWidth: 130,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: ICellRendererParams<IData, number>) =>
				params.value ? (
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>12/02 06:16:21</Tooltip>}
					>
						<span className="green">{params.value}m </span>
					</OverlayTrigger>
				) : (
					'-'
				)
		},
		{
			field: 'holders',
			headerName: 'Holders',
			minWidth: 110,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			filter: 'agNumberColumnFilter'
		},
		{
			field: 'txs',
			headerName: '1h TXs',
			minWidth: 110,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			filter: 'agNumberColumnFilter',
			cellStyle: {
				color: '#fff',
				fontWeight: 500
			}
		},
		{
			field: 'volume',
			headerName: 'Vol',
			minWidth: 130,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: ValueFormatterParams<IData, number>) =>
				`${getFullDisplayBalance(params.value)}`,
			cellStyle: {
				color: '#fff',
				fontWeight: 500
			}
		},
		{
			field: 'mktCap',
			headerName: 'MKT Cap',
			minWidth: 120,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: ValueFormatterParams<IData, number>) =>
				`$${params.value}k`,
			cellStyle: {
				color: '#fff',
				fontWeight: 500
			}
		},
		{
			field: 'last',
			headerName: 'Last',
			minWidth: 110,
			flex: 1,
			sortable: true,
			unSortIcon: true,
			cellRenderer: (params: ICellRendererParams<IData, number>) => (
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip>12/02 06:16:21</Tooltip>}
				>
					<span className="green">{params.value}m </span>
				</OverlayTrigger>
			)
		},
		{
			field: 'dev',
			headerName: 'DEV',
			minWidth: 110,
			flex: 1,
			filter: 'agTextColumnFilter',
			cellRenderer: (params: ICellRendererParams<IData, number>) => (
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip>12/02 06:16:21</Tooltip>}
				>
					<span className="green">{params.value}m </span>
				</OverlayTrigger>
			)
		},
		{
			field: '',
			headerName: '',
			pinned: 'right',
			minWidth: 85,
			maxWidth: 85,
			flex: 1,
			cellRenderer: () => (
				<Button
					variant="secondary"
					className="d-inline-flex align-items-center gap-1"
					size="sm"
				>
					<img src="/assets/images/icons/icon-buy.svg" height={14} alt="" />
					<span>Buy</span>
				</Button>
			)
		}
	];

	const getDatasource = (activeKey: string) => {
		switch (activeKey) {
			case TABS[1].key:
				return newTokens;
			case TABS[2].key:
				return hotTokens;
			case TABS[3].key:
				return topTokens;
			case TABS[4].key:
				return myTokens;

			default:
				break;
		}
	};
	return (
		<div className="mm-meme">
			<Helmet>
				<title>Meme</title>
				<meta name="description" content="Your page description." />
				<meta property="og:title" content="Your Open Graph Title" />
				<meta property="og:description" content="Your Open Graph Description" />
				<meta property="og:image" content="url-to-your-image" />
				{/* Add more meta tags as needed */}
			</Helmet>
			<div className="mm-page-header">
				<div className="mm-page-header-left">
					<Button variant="text" className="btn-tab px-1 active">
						<img src="/assets/images/icons/icon-pump.svg" alt="" />
						<span className="ms-2">Pump</span>
					</Button>
					<Button variant="text" className="btn-tab px-1">
						<img src="/assets/images/icons/icon-moon.svg" alt="" />
						<span className="ms-2">Moonshot</span>
					</Button>
					<ButtonTabs
						items={TABS}
						activeKey={activeTabKey}
						onChange={onTabChange}
					/>
					<Button size="sm" variant="outline-success">
						<span className="me-1">Pump Alert</span>
						<img src="/assets/images/icons/icon-right.svg" alt="" />
					</Button>
				</div>
				<div className="mm-page-header-right">
					<img src="/assets/images/icons/icon-buy.svg" alt="buy" />
					<span>Linked Buy</span>
					<div className="input-wrap">
						<img src="/assets/images/icons/icon-solana.svg" alt="sol" />
						<FormControl type="number" size="sm" />
					</div>
					<button type="button">
						<img src="/assets/images/icons/icon-setting.svg" alt="setting" />
					</button>
				</div>
			</div>

			{/* TAB 1 */}
			{activeTabKey === TABS[0].key ? (
				<Row className="gx-3">
					<Col md={4}>
						<Card className="full">
							<Card.Header>
								<Card.Title>🌱 New Creations</Card.Title>
								<Dropdown>
									<Dropdown.Toggle variant="text" size="sm" id="dropdown-basic">
										<img src="/assets/images/icons/icon-filter.svg" alt="" />
										<span>Filter</span>
									</Dropdown.Toggle>

									<Dropdown.Menu align="end">
										<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
										<Dropdown.Item href="#/action-2">
											Another action
										</Dropdown.Item>
										<Dropdown.Item href="#/action-3">
											Something else
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Card.Header>
							<Card.Body>
								{newTokens.map((item: any, index: number) => {
									return (
										<MemeCard data={item} key={'pump-new-token' + index} />
									);
								})}
							</Card.Body>
						</Card>
					</Col>
					<Col md={4}>
						<Card className="full">
							<Card.Header>
								<Card.Title>💊 Hot</Card.Title>
								<Dropdown>
									<Dropdown.Toggle variant="text" size="sm" id="dropdown-basic">
										<img src="/assets/images/icons/icon-filter.svg" alt="" />
										<span>Filter</span>
									</Dropdown.Toggle>

									<Dropdown.Menu align="end">
										<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
										<Dropdown.Item href="#/action-2">
											Another action
										</Dropdown.Item>
										<Dropdown.Item href="#/action-3">
											Something else
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Card.Header>
							<Card.Body>
								{hotTokens.map((item: any, index: number) => {
									return (
										<MemeCard data={item} key={'pump-hot-token' + index} />
									);
								})}
							</Card.Body>
						</Card>
					</Col>
					<Col md={4}>
						<Card className="full">
							<Card.Header>
								<Card.Title>🐣 Top</Card.Title>
								<Dropdown>
									<Dropdown.Toggle variant="text" size="sm" id="dropdown-basic">
										<img src="/assets/images/icons/icon-filter.svg" alt="" />
										<span>Filter</span>
									</Dropdown.Toggle>

									<Dropdown.Menu align="end">
										<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
										<Dropdown.Item href="#/action-2">
											Another action
										</Dropdown.Item>
										<Dropdown.Item href="#/action-3">
											Something else
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Card.Header>
							<Card.Body>
								{newTokens.map((item: any, index: number) => {
									return (
										<MemeCard data={item} key={'pump-top-token' + index} />
									);
								})}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			) : (
				<></>
			)}

			{/* TAB 2 */}

			<Card className="full no-header">
				<Card.Body className="p-0">
					<AgGridReact
						className="ag-theme-quartz ag-theme-quartz-dark"
						rowData={getDatasource(activeTabKey)}
						columnDefs={COLUMNS}
						rowHeight={55}
					/>
				</Card.Body>
			</Card>
			{/* <AdvancedRealTimeChart theme="dark" autosize></AdvancedRealTimeChart> */}
		</div>
	);
};
export default DashboardPage;
