import { useState } from 'react';
import './index.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const QuickView = () => {
	const [activeTab, setActiveTab] = useState(1);
	const handleTabChange = (newTab: number) => {
		setActiveTab(newTab);
	};
	return (
		<div className="quick-view">
			<div className="tabs">
				<button
					type="button"
					onClick={() => handleTabChange(1)}
					className={activeTab === 1 ? 'active' : ''}
				>
					<span>1m</span>
					<span className="green">0.47%</span>
				</button>
				<button
					type="button"
					onClick={() => handleTabChange(2)}
					className={activeTab === 2 ? 'active' : ''}
				>
					<span>5m</span>
					<span>0%</span>
				</button>
				<button
					type="button"
					onClick={() => handleTabChange(3)}
					className={activeTab === 3 ? 'active' : ''}
				>
					<span>1h</span>
					<span className="red">-2.73%</span>
				</button>
				<button
					type="button"
					onClick={() => handleTabChange(4)}
					className={activeTab === 4 ? 'active' : ''}
				>
					<span>24h</span>
					<span className="red">-27.32%</span>
				</button>
			</div>

			<div className="items">
				<div className="item">
					<span>Vol</span>
					<span>$0.439</span>
				</div>
				<div className="item">
					<span>Buys</span>
					<span className="green">0/$0</span>
				</div>
				<div className="item">
					<span>Sells</span>
					<span className="red">1/$0.439</span>
				</div>
				<div className="item">
					<span>Net Buy</span>
					<span>--</span>
				</div>
			</div>

			<div className="items">
				<div className="item">
					<span>FDV</span>
					<span>$4,780.15</span>
				</div>
				<div className="item">
					<span>MKT Cap</span>
					<span>$4,780.15</span>
				</div>
				<div className="item">
					<span>24h Vol</span>
					<span>$299.5K</span>
				</div>
				<div className="item">
					<span>Holders</span>
					<span>125</span>
				</div>
			</div>

			<div className="items">
				<div className="item">
					<span>Pair</span>
					<span>iris</span>
					<span>SOL</span>
				</div>
				<div className="item align-items-center">
					<span>Liq/Initial</span>
					<span>872.4M/206.9M(20.7%)</span>
					<span>
						<span className="red">19.49</span>/79.01
						<span className="red">(-75.3%)</span>
					</span>
				</div>
				<div className="item">
					<span>Value</span>
					<span>$4,171.38</span>
					<span>$4,181.59</span>
				</div>
			</div>

			<div className="items">
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="tooltip-qv-1">Mint auth disabled</Tooltip>}
				>
					<div className="item">
						<span>NoMint</span>
						<span className="green">
							Yes
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14px"
								height="14px"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M14.78 3.47a.75.75 0 010 1.06l-8 8a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 011.06-1.06l3.97 3.97 7.47-7.47a.75.75 0 011.06 0z"></path>
							</svg>
						</span>
					</div>
				</OverlayTrigger>

				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="tooltip-qv-2">Frozen blacklist</Tooltip>}
				>
					<div className="item">
						<span>Blacklist</span>
						<span className="green">
							No
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14px"
								height="14px"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M14.78 3.47a.75.75 0 010 1.06l-8 8a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 011.06-1.06l3.97 3.97 7.47-7.47a.75.75 0 011.06 0z"></path>
							</svg>
						</span>
					</div>
				</OverlayTrigger>

				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="tooltip-qv-3">100% liquidity Burnt🔥</Tooltip>}
				>
					<div className="item">
						<span>Burnt</span>
						<span className="green">100% 🔥</span>
					</div>
				</OverlayTrigger>

				<OverlayTrigger
					placement="bottom"
					overlay={
						<Tooltip id="tooltip-qv-4">Top 10 own less than 30%</Tooltip>
					}
				>
					<div className="item">
						<span>Top 10</span>
						<span className="green">
							9.55%
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14px"
								height="14px"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M14.78 3.47a.75.75 0 010 1.06l-8 8a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 011.06-1.06l3.97 3.97 7.47-7.47a.75.75 0 011.06 0z"></path>
							</svg>
						</span>
					</div>
				</OverlayTrigger>

				<div className="item full">
					<span>Insiders</span>
					<OverlayTrigger
						placement="bottom"
						overlay={
							<Tooltip id="tooltip-qv-5">
								Insiders' holding quantity is 0% of the total
							</Tooltip>
						}
					>
						<span className="green">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14px"
								height="14px"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.5 8a3.5 3.5 0 10-3.463-2.988 2.953 2.953 0 00-.1-.012C7.844 5 6.274 7.972 6 9.457c.379.46 1.49 1.495 2.905 1.947-1.099 1.254-1.56 4.194-1.71 6.275-1.042-.093-2.369-.287-3.614-.653-.677-.2-1.047-.599-1.247-1.116-.211-.546-.242-1.248-.15-2.021.117-1.003.432-1.897.696-2.648.056-.158.11-.31.158-.456.08-.236.149-.44.198-.604.233-.779-.11-1.498-.532-1.914-.408-.404-1.243-.744-1.638-.744v.986c.268.086.65.274.914.537.253.25.356.51.262.823-.038.126-.096.298-.166.506-.073.215-.176.476-.291.768-.326.828-.752 1.905-.867 2.893-.1.843-.148 1.732.148 2.497.307.793 1.1 1.44 2.17 1.884 1.711.595 3.153.595 4.09.595h.011L7.349 19h9.64c.045-.268.026-6.977-1.804-11.014.104.01.209.014.315.014zM11 8a1 1 0 11-2 0 1 1 0 012 0z"
								></path>
								<path d="M11 4c0 .039 0 .078-.002.116-2.138.334-3.601 1.338-4.553 2.45A3 3 0 1111 4z"></path>
							</svg>
							0%
						</span>
					</OverlayTrigger>
				</div>
			</div>
		</div>
	);
};
export default QuickView;
