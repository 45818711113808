import { Web3Provider } from '@ethersproject/providers';
import { CurrentConfig, ENV_CONFIGS } from '../config';
import { ACTION_STATUS, MAX_UINT } from '../constants';
import Web3, { PayableCallOptions } from 'web3';
import { AbiItem } from 'web3-utils';
import MEME_ABI from '../constants/abi/meme.abi.json';

import {
	getMarketContractByProvider,
	getMemeContractByProvider,
	getMemeTokenContractByProvider
} from '../utils/utils';
import { get } from 'lodash';
import BigNumber from 'bignumber.js';

export interface ICreateMemeToken {
	name: string;
	symbol: string;
	nonce: number;
	signature: any;
	web3Provider: Web3Provider;
	account: string;
}

export const UseCreateMemeToken = async (
	{ name, symbol, nonce, signature, web3Provider, account }: ICreateMemeToken,
	callback: any
) => {
	console.log('la sao ta', name);
	const contractAddress = ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS;

	if (!name || symbol.length < 0 || !web3Provider || !account) {
		return callback({ status: ACTION_STATUS.CREATE_MEME_TOKEN_FAIL });
	}

	let sendObject = { from: account };
	const memeContract = getMemeContractByProvider(contractAddress, web3Provider);

	callback({
		status: ACTION_STATUS.CREATE_MEME_TOKEN_SUBMITTING
	});
	try {
		const web3 = new Web3(web3Provider.provider as any);
		const method = memeContract.methods.createToken(
			name,
			symbol,
			nonce,
			signature
		);

		await web3.eth.call({
			from: account,
			to: contractAddress,
			data: method.encodeABI()
		});

		return method
			.send(sendObject)
			.on('error', (error: any) => {
				console.log(error);
				callback({
					status: ACTION_STATUS.CREATE_MEME_TOKEN_FAIL,
					message: get(error, 'message', '')
				});
			})
			.then((receipt: any) => {
				if (receipt.status == true) {
					callback({
						status: ACTION_STATUS.CREATE_MEME_TOKEN_SUCCESS,
						data: receipt.transactionHash
					});
				} else callback({ status: ACTION_STATUS.CREATE_MEME_TOKEN_FAIL });
			})
			.catch((err: any) => {
				console.log(err);
				callback({
					status: ACTION_STATUS.CREATE_MEME_TOKEN_FAIL,
					message: get(err, 'data.message', '')
				});
			});
	} catch (err: any) {
		// debugger
		callback({
			status: ACTION_STATUS.CREATE_MEME_TOKEN_FAIL,
			message: err.message
		});
	}
};

/**
 * ==================================
 * Handle buy exact in
 */
export interface ICBuyExactIn {
	amount: number;
	amountOutMin: number;
	tokenAddress: string;
	web3Provider: Web3Provider;
	account: string;
}

export const UseBuyExactIn = async (
	{ amount, amountOutMin, tokenAddress, web3Provider, account }: ICBuyExactIn,
	callback: any
) => {
	const contractAddress = ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS;
	if (!tokenAddress || !web3Provider || !account) {
		return callback({ status: ACTION_STATUS.BUY_EXACT_IN_FAIL });
	}

	let sendObject: PayableCallOptions = {
		from: account,
		value: new BigNumber(amount).multipliedBy(10 ** 18).toString()
	};
	const memeContract = getMemeContractByProvider(contractAddress, web3Provider);

	callback({
		status: ACTION_STATUS.BUY_EXACT_IN_SUBMITTING
	});
	try {
		const web3 = new Web3(web3Provider.provider as any);
		const method = memeContract.methods.buyExactIn(tokenAddress, amountOutMin);

		await web3.eth.call({
			from: account,
			to: contractAddress,
			data: method.encodeABI()
			// value: new BigNumber(amount).multipliedBy(10 ** 18).toString()
		});

		return method
			.send(sendObject)
			.on('error', (error: any) => {
				console.log(error);
				callback({
					status: ACTION_STATUS.BUY_EXACT_IN_FAIL,
					message: get(error, 'message', '')
				});
			})
			.then((receipt: any) => {
				if (receipt.status == true) {
					callback({
						status: ACTION_STATUS.BUY_EXACT_IN_SUCCESS,
						data: receipt.transactionHash
					});
				} else callback({ status: ACTION_STATUS.BUY_EXACT_IN_FAIL });
			})
			.catch((err: any) => {
				console.log('111111', err);
				callback({
					status: ACTION_STATUS.BUY_EXACT_IN_FAIL,
					message: String(err)?.includes('User denied transaction signature')
						? ''
						: String(err)
				});
			});
	} catch (err: any) {
		// debugger
		callback({
			status: ACTION_STATUS.BUY_EXACT_IN_FAIL,
			message: err.message
		});
	}
};
/**
 * ==================================
 */

/**
 * ==================================
 * Handle buy exact out
 */
export interface ICBuyExactOut {
	tokenAddress: string;
	tokenAmount: number;
	maxCollateralAmount: number;
	web3Provider: Web3Provider;
	account: string;
	fee: number;
}

export const UseBuyExactOut = async (
	{
		tokenAmount,
		maxCollateralAmount,
		tokenAddress,
		web3Provider,
		account,
		fee
	}: ICBuyExactOut,
	callback: any
) => {
	const contractAddress = ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS;

	if (
		!tokenAmount ||
		!maxCollateralAmount ||
		!tokenAddress ||
		!web3Provider ||
		!account
	) {
		return callback({ status: ACTION_STATUS.BUY_EXACT_OUT_FAIL });
	}
	let sendObject = {
		from: account,
		value: new BigNumber(maxCollateralAmount).toString() //fee.toString() //new BigNumber(151317210).multipliedBy(10 ** 3).toString()
	};
	const memeContract = getMemeContractByProvider(contractAddress, web3Provider);

	callback({
		status: ACTION_STATUS.BUY_EXACT_OUT_SUBMITTING
	});
	try {
		const web3 = new Web3(web3Provider.provider as any);
		console.log(
			'111111',
			{
				tokenAddress,
				tokenAmount,
				maxCollateralAmount
			},
			new BigNumber(fee).multipliedBy(10).toString()
		);
		const method = memeContract.methods.buyExactOut(
			tokenAddress,
			new BigNumber(tokenAmount).multipliedBy(10 ** 18).toNumber(),
			maxCollateralAmount
		);

		return method
			.send(sendObject)
			.on('error', (error: any) => {
				console.log(error);
				callback({
					status: ACTION_STATUS.BUY_EXACT_OUT_FAIL,
					message: get(error, 'message', '')
				});
			})
			.then((receipt: any) => {
				if (receipt.status == true) {
					callback({
						status: ACTION_STATUS.BUY_EXACT_OUT_SUCCESS,
						data: receipt.transactionHash
					});
				} else callback({ status: ACTION_STATUS.BUY_EXACT_OUT_FAIL });
			})
			.catch((err: any) => {
				console.log(err);
				callback({
					status: ACTION_STATUS.BUY_EXACT_OUT_FAIL,
					message: String(err)?.includes('User denied transaction signature')
						? ''
						: String(err)
				});
			});
	} catch (err: any) {
		// debugger
		callback({
			status: ACTION_STATUS.BUY_EXACT_OUT_FAIL,
			message: err.message
		});
	}
};
/**
 * ==================================
 */

/**
 * ==================================
 * Handle sell exact in
 */
export interface ICSellExactIn {
	tokenAmount: number;
	amountCollateralMin: number;
	tokenAddress: string;
	web3Provider: Web3Provider;
	account: string;
}

export const UseSellExactIn = async (
	{
		tokenAmount,
		amountCollateralMin,
		tokenAddress,
		web3Provider,
		account
	}: ICSellExactIn,
	callback: any
) => {
	const contractAddress = ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS;
	if (!tokenAddress || !web3Provider || !account) {
		return callback({ status: ACTION_STATUS.SELL_EXACT_IN_FAIL });
	}

	let sendObject: PayableCallOptions = {
		from: account
		// value: new BigNumber(tokenAmount).multipliedBy(10 ** 18).toString()
	};
	const memeContract = getMemeContractByProvider(contractAddress, web3Provider);

	callback({
		status: ACTION_STATUS.SELL_EXACT_IN_SUBMITTING
	});
	try {
		const web3 = new Web3(web3Provider.provider as any);
		const method = memeContract.methods.sellExactIn(
			tokenAddress,
			new BigNumber(tokenAmount).multipliedBy(10 ** 18).toNumber(),
			amountCollateralMin
		);

		await web3.eth.call({
			from: account,
			to: contractAddress,
			data: method.encodeABI()
			// value: new BigNumber(amount).multipliedBy(10 ** 18).toString()
		});

		return method
			.send(sendObject)
			.on('error', (error: any) => {
				console.log(error);
				callback({
					status: ACTION_STATUS.SELL_EXACT_IN_FAIL,
					message: get(error, 'message', '')
				});
			})
			.then((receipt: any) => {
				if (receipt.status == true) {
					callback({
						status: ACTION_STATUS.SELL_EXACT_IN_SUCCESS,
						data: receipt.transactionHash
					});
				} else callback({ status: ACTION_STATUS.SELL_EXACT_IN_FAIL });
			})
			.catch((err: any) => {
				console.log('111111', err);
				callback({
					status: ACTION_STATUS.SELL_EXACT_IN_FAIL,
					message: String(err)?.includes('User denied transaction signature')
						? ''
						: String(err)
				});
			});
	} catch (err: any) {
		// debugger
		callback({
			status: ACTION_STATUS.SELL_EXACT_IN_FAIL,
			message: err.message
		});
	}
};
/**
 * ==================================
 */

/**
 * ==================================
 * Handle sell exact out
 */
export interface ICSellExactOut {
	tokenAmountMax: number;
	amountCollateral: number;
	tokenAddress: string;
	web3Provider: Web3Provider;
	account: string;
}

export const UseSellExactOut = async (
	{
		tokenAmountMax,
		amountCollateral,
		tokenAddress,
		web3Provider,
		account
	}: ICSellExactOut,
	callback: any
) => {
	const contractAddress = ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS;
	if (!tokenAddress || !web3Provider || !account) {
		return callback({ status: ACTION_STATUS.SELL_EXACT_OUT_FAIL });
	}

	let sendObject: PayableCallOptions = {
		from: account
		// value: new BigNumber(tokenAmount).multipliedBy(10 ** 18).toString()
	};
	const memeContract = getMemeContractByProvider(contractAddress, web3Provider);

	callback({
		status: ACTION_STATUS.SELL_EXACT_OUT_SUBMITTING
	});
	try {
		const web3 = new Web3(web3Provider.provider as any);
		const method = memeContract.methods.sellExactOut(
			tokenAddress,
			tokenAmountMax,
			new BigNumber(amountCollateral).multipliedBy(10 ** 18).toNumber()
		);

		await web3.eth.call({
			from: account,
			to: contractAddress,
			data: method.encodeABI()
			// value: new BigNumber(amount).multipliedBy(10 ** 18).toString()
		});

		return method
			.send(sendObject)
			.on('error', (error: any) => {
				console.log(error);
				callback({
					status: ACTION_STATUS.SELL_EXACT_OUT_FAIL,
					message: get(error, 'message', '')
				});
			})
			.then((receipt: any) => {
				if (receipt.status == true) {
					callback({
						status: ACTION_STATUS.SELL_EXACT_OUT_SUCCESS,
						data: receipt.transactionHash
					});
				} else callback({ status: ACTION_STATUS.SELL_EXACT_OUT_FAIL });
			})
			.catch((err: any) => {
				console.log(err);
				callback({
					status: ACTION_STATUS.SELL_EXACT_OUT_FAIL,
					message: get(err, 'data.message', '')
				});
			});
	} catch (err: any) {
		// debugger
		callback({
			status: ACTION_STATUS.SELL_EXACT_OUT_FAIL,
			message: err.message
		});
	}
};
/**
 * ==================================
 */

/**
 * ==================================
 * Handle get amount in and fee
 */
export interface IGetAmountInAndFee {
	amountOut: number;
	reserveIn: number;
	reserveOut: number;
	payment?: boolean;
	currencyAddress: any;
	web3Provider: Web3Provider;
}

export async function UseGetAmountInAndFee({
	amountOut,
	reserveIn,
	reserveOut,
	payment,
	currencyAddress,
	web3Provider
}: IGetAmountInAndFee) {
	const tokenContract = getMemeTokenContractByProvider(
		currencyAddress,
		web3Provider
	);
	return await tokenContract.methods
		.getAmountInAndFee(amountOut, reserveIn, reserveOut, payment ?? false)
		.call();
}
/**
 * ==================================
 */

/**
 * ==================================
 * Handle get amount out and fee
 */
export interface IGetAmountOutAndFee {
	amountIn: number;
	reserveIn: number;
	reserveOut: number;
	currencyAddress: any;
	web3Provider: Web3Provider;
}

export async function UseGetAmountOutAndFee({
	amountIn,
	reserveIn,
	reserveOut,
	currencyAddress,
	web3Provider
}: IGetAmountOutAndFee) {
	const tokenContract = getMemeTokenContractByProvider(
		currencyAddress,
		web3Provider
	);
	return await tokenContract.methods
		.getAmountOutAndFee(amountIn, reserveIn, reserveOut, false)
		.call();
}

export async function UseGetAmountOutAndFeeWithFactory({
	amountIn,
	reserveIn,
	reserveOut,
	currencyAddress,
	web3Provider
}: IGetAmountOutAndFee) {
	const tokenContract = getMemeTokenContractByProvider(
		ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS,
		web3Provider
	);
	return await tokenContract.methods
		.getAmountOutAndFee(amountIn, reserveIn, reserveOut, false)
		.call();
}
/**
 * ==================================
 */

/**
 * ==================================
 * Handle get virtual resource
 */
export interface IGetVirtualReserves {
	currencyAddress: any;
	web3Provider: Web3Provider;
}

export async function UseGetVirtualCollateralReserves({
	currencyAddress,
	web3Provider
}: IGetVirtualReserves) {
	const tokenContract = getMemeTokenContractByProvider(
		currencyAddress,
		web3Provider
	);
	return await tokenContract.methods.virtualCollateralReserves().call();
}

export async function UseGetVirtualTokenReserves({
	currencyAddress,
	web3Provider
}: IGetVirtualReserves) {
	const tokenContract = getMemeTokenContractByProvider(
		currencyAddress,
		web3Provider
	);
	return await tokenContract.methods.virtualTokenReserves().call();
}

/**
 * ==================================
 */

/**
 * Approve token for factory
 */
export interface IApproveToken {
	tokenAddress: string;
	web3Provider: Web3Provider;
	account: string;
	amount: number;
}

export const UseMemeTokenApproveCallback = (
	{ tokenAddress, web3Provider, account, amount }: IApproveToken,
	callback: any
) => {
	const factoryAddress = ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS;

	const tokenContract = getMemeTokenContractByProvider(
		tokenAddress,
		web3Provider
	);

	callback({
		status: ACTION_STATUS.APPROVING
	});

	return tokenContract.methods
		.approve(
			factoryAddress,
			new BigNumber(amount).multipliedBy(10 ** 18).toNumber()
		)
		.send({ from: account })
		.on('error', (error: any) => {
			console.log(error);
			callback({
				status: ACTION_STATUS.APPROVE_FAILS
			});
		})
		.then((receipt: any) => {
			if (receipt.status == true) {
				callback({
					status: ACTION_STATUS.APPROVED
				});
			} else callback({ status: ACTION_STATUS.APPROVE_FAILS });
		});
};

export interface IAllowanceToken {
	tokenAddress: string;
	web3Provider: Web3Provider;
	account: string;
	contract?: string;
}

export const getAllowanceMemeToken = async ({
	tokenAddress,
	web3Provider,
	account
}: IAllowanceToken) => {
	const tokenContract = getMemeTokenContractByProvider(
		tokenAddress,
		web3Provider
	);

	const allocationNumber: any = await tokenContract.methods
		.allowance(account, ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS)
		.call();
	return new BigNumber(allocationNumber.toString())
		.dividedBy(10 ** 18)
		.toString();
};

interface IGetPriceToken {
	tokenAddress: string;
}

export const getPriceToken = async ({ tokenAddress }: IGetPriceToken) => {
	const contractAddress = ENV_CONFIGS.FACTORY_MEME_CONTRACT_ADDRESS;
	const web3 = new Web3(CurrentConfig.rpc.sepolia);
	const factoryContract = new web3.eth.Contract(
		MEME_ABI as AbiItem[],
		contractAddress
	);

	const allocationNumber: any = await factoryContract.methods
		.getPrice(tokenAddress)
		.call();
	return new BigNumber(allocationNumber.toString()).toNumber();
};
