import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface ILoadingButtonProps {
	isLoading?: boolean;
	handlePress: any;
	title: string;
	disable?: boolean;
}

const LoadingButton = ({
	isLoading,
	handlePress,
	title,
	disable
}: ILoadingButtonProps) => {
	return (
		<Button
			className="w-100 d-flex align-items-center justify-content-center"
			variant="warning"
			size="lg"
			onClick={handlePress}
			disabled={isLoading || disable}
		>
			{isLoading ? (
				<Spinner
					as="span"
					color="#ffffff"
					animation="border"
					size="sm"
					role="status"
					aria-hidden="true"
				/>
			) : (
				title
			)}
		</Button>
	);
};

export default LoadingButton;
