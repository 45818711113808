import {
	SET_AVAX_BALANCE_SUCCESS,
	SET_USDC_BALANCE_SUCCESS,
	SET_EKL_BALANCE_SUCCESS,
	SET_ETH_BALANCE_SUCCESS
} from '../actions/wallet';

export const initialState: any = {
	avaxBalance: 0,
	eklBalance: 0,
	usdcBalance: 0,
	ethBalance: 0
};

const walletReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_ETH_BALANCE_SUCCESS:
			return {
				...state,
				ethBalance: action.payload
			};
		case SET_AVAX_BALANCE_SUCCESS:
			return {
				...state,
				avaxBalance: action.payload
			};
		case SET_EKL_BALANCE_SUCCESS:
			return {
				...state,
				eklBalance: action.payload
			};
		case SET_USDC_BALANCE_SUCCESS:
			return {
				...state,
				usdcBalance: action.payload
			};

		default:
			return {
				...state
			};
	}
};
export default walletReducer;
