import { FormCheck, Tab, Tabs } from 'react-bootstrap';
import './index.scss';
import { AgGridReact } from 'ag-grid-react';
const WatchPanel = () => {
	const WATCHLIST_DATA = [];
	const WATCHLIST_COLUMN = [
		{
			field: 'token',
			headerName: 'Token',
			flex: 1,
			sortable: true,
			unSortIcon: true
		},
		{
			field: 'price',
			headerName: 'Price',
			flex: 1,
			sortable: true,
			unSortIcon: true
		}
	];
	const HOLDING_DATA = [];
	const HOLDING_COLUMN = [
		{
			field: 'lastActive',
			headerName: 'Last Active',
			minWidth: 85,
			maxWidth: 85,
			sortable: true,
			unSortIcon: true
		},
		{
			field: 'bal',
			headerName: 'Bal',
			flex: 1,
			sortable: true,
			unSortIcon: true
		},
		{
			field: 'pnl',
			headerName: 'PnL',
			minWidth: 70,
			maxWidth: 70,
			sortable: true,
			unSortIcon: true
		},
		{
			field: 'usd',
			headerName: 'USD',
			minWidth: 65,
			maxWidth: 65,
			sortable: true,
			unSortIcon: true
		}
	];
	return (
		<div className="watch-panel">
			<Tabs defaultActiveKey="watchlist" id="uncontrolled-tab-example">
				<Tab eventKey="watchlist" title="Watchlist">
					<div className="tab-search">
						<div className="search">
							<img src="/assets/images/icons/icon-search.svg" alt="" />
							<input
								className="form-control form-control-sm"
								placeholder="Search token"
							/>
						</div>
					</div>
					<div className="table-wrap">
						<AgGridReact
							className="ag-theme-quartz ag-theme-quartz-dark"
							rowData={WATCHLIST_DATA}
							columnDefs={WATCHLIST_COLUMN}
							rowHeight={28}
							domLayout="autoHeight"
						/>
					</div>
				</Tab>
				<Tab eventKey="holding" title="Holding">
					<div className="tab-check">
						<FormCheck id={`chk-low`} label="Low Liq/Honeypot" />
						<FormCheck id={`chk-small`} label="Small" />
					</div>
					<div className="table-wrap">
						<AgGridReact
							className="ag-theme-quartz ag-theme-quartz-dark"
							rowData={HOLDING_DATA}
							columnDefs={HOLDING_COLUMN}
							rowHeight={28}
							domLayout="autoHeight"
						/>
					</div>
				</Tab>
				<Tab eventKey="following" title="Following">
					Tab content for Contact
				</Tab>
			</Tabs>
		</div>
	);
};
export default WatchPanel;
