import React, { useEffect, useState } from 'react';

import { useWeb3React } from '@web3-react/core';
import { ConnectionOptions } from '../evm-web3/ConnectionOptions';
import useModal from './useModal';
import { ConnectionType } from '../../libs/connections';
import { signMessage } from '../../utils/web3';
import { executeUserLogin } from '../../redux/services/user';
import { STORAGE_KEY } from '../../constants';
import { get } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { GET_USER_INFO_REQUEST } from '../../redux/actions/user';

interface ReturnType {
	onPresentConnectModal: () => void;
}

const useWalletModal = (): ReturnType => {
	const dispatch = useDispatch();
	const { chainId, account, isActive, provider } = useWeb3React();
	const [connectionType, setConnectionType] = useState<ConnectionType | null>(
		null
	);

	//doing this to test the sign message function
	useEffect(() => {
		const fetchSignedData = async () => {
			if (!provider || !account) return; // If provider is null, exit
			try {
				const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
				const expiredTime = localStorage.getItem(STORAGE_KEY.EXPIRED_TIME);
				const refId = localStorage.getItem(STORAGE_KEY.REFERRAL_ID);
				if (
					!accessToken ||
					!expiredTime ||
					Number(expiredTime) * 1000 <= Date.now()
				) {
					const accounts = await window.ethereum.request({
						method: 'eth_requestAccounts'
					});
					if (!accounts || accounts.length === 0) {
						console.error('No accounts connected');
						return;
					}
					const signer = provider.getSigner();
					const dataSigned = await signMessage(signer, 'LOGIN');
					console.log(dataSigned);
					const res = await executeUserLogin(
						'LOGIN',
						dataSigned,
						account,
						refId ?? '',
						'evm'
					);
					localStorage.setItem(STORAGE_KEY.SIGNATURE, dataSigned);
					localStorage.setItem(STORAGE_KEY.REFERRAL_ID, '');
					localStorage.setItem(
						STORAGE_KEY.ACCESS_TOKEN,
						get(res, 'access_token', '')
					);
					const decoded = jwtDecode(get(res, 'access_token', ''));
					localStorage.setItem(STORAGE_KEY.EXPIRED_TIME, String(decoded.exp));
					dispatch({ type: GET_USER_INFO_REQUEST });
				} else {
					dispatch({ type: GET_USER_INFO_REQUEST });
				}
			} catch (error) {
				console.error('Error signing message:', error);
			}
		};

		// Call the async function
		fetchSignedData();
	}, [chainId, account, provider]);

	const [onPresentConnectModal] = useModal(
		<ConnectionOptions
			activeConnectionType={connectionType}
			isConnectionActive={isActive}
			onActivate={setConnectionType}
			onDeactivate={setConnectionType}
		/>
	);
	return { onPresentConnectModal };
};

export default useWalletModal;
