import {
	GET_HOT_LIST_TOKEN_SUCCESS,
	GET_NEW_TOKEN_SUCCESS,
	GET_TOP_TOKEN_SUCCESS,
	GET_USER_LIST_TOKEN_SUCCESS
} from '../actions/user';

export const initialState: any = {
	myTokens: [],
	newTokens: [],
	hotTokens: [],
	topTokens: []
};

const tokenReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case GET_USER_LIST_TOKEN_SUCCESS:
			return {
				...state,
				myTokens: action.payload
			};
		case GET_HOT_LIST_TOKEN_SUCCESS:
			return {
				...state,
				hotTokens: action.payload
			};
		case GET_NEW_TOKEN_SUCCESS:
			return {
				...state,
				newTokens: action.payload
			};
		case GET_TOP_TOKEN_SUCCESS:
			return {
				...state,
				topTokens: action.payload
			};

		default:
			return {
				...state
			};
	}
};
export default tokenReducer;
