import BigNumber from 'bignumber.js';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Audit from '../components/token-detail/audit';
import BuyPanel from '../components/token-detail/buy-panel';
import ConnectTGBot from '../components/token-detail/connect-tg-bot';
import PoolInfo from '../components/token-detail/pool-info';
import QuickLinks from '../components/token-detail/quick-links';
import QuickView from '../components/token-detail/quick-view';
import TrendingPanel from '../components/token-detail/trending-panel';
import UpdateToken from '../components/token-detail/update-token';
import Vote from '../components/token-detail/vote';
import WatchPanel from '../components/token-detail/watch-panel';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../config';
import { ACTION_STATUS } from '../constants';
import { useActiveWeb3React } from '../hooks';
import {
	getAllowanceMemeToken,
	UseBuyExactIn,
	UseBuyExactOut,
	UseGetAmountInAndFee,
	UseGetAmountOutAndFee,
	UseGetVirtualCollateralReserves,
	UseGetVirtualTokenReserves,
	UseMemeTokenApproveCallback,
	UseSellExactIn,
	UseSellExactOut
} from '../hooks/useMemeToken';

import CandlestickChart from '../components/token-detail/candle-chart';
import ActivityPanel from '../components/token-detail/activity-panel';
import { fetchTokenDetailByContract } from '../redux/services/token';
import { GET_HOT_LIST_TOKEN_REQUEST } from '../redux/actions/user';
import socket from '../utils/socket';

const TokenPage = () => {
	const { tokenAddress } = useParams();
	const { account, provider } = useActiveWeb3React();
	const dispatch = useDispatch();

	const [tokenDetail, setTokenDetail] = useState<any>();
	const [tokenPrice, setTokenPrice] = useState<any>(null);

	useEffect(() => {
		// Connect the socket
		socket.connect();

		socket.on('connect', () => {
			console.log('Connected to the server');
		});

		socket.on(`TOKEN_PRICE_${tokenAddress}`, data => {
			console.log('token price====', data);
			setTokenPrice(data);
		});

		socket.on('disconnect', () => {
			console.log('Disconnected from the server');
		});

		// Cleanup on unmount
		return () => {
			socket.off('connect');
			socket.off('disconnect');
			socket.disconnect();
		};
	}, []);

	useEffect(() => {
		// dispatch({ type: GET_USER_LIST_TOKEN_REQUEST });
		dispatch({ type: GET_HOT_LIST_TOKEN_REQUEST });
		// dispatch({ type: GET_TOP_TOKEN_REQUEST });
		// dispatch({ type: GET_NEW_TOKEN_REQUEST });
	}, []);
	useEffect(() => {
		if (tokenAddress) {
			fetchTokenInfoByContract();
		}
	}, [tokenAddress]);

	const fetchTokenInfoByContract = async () => {
		try {
			const res = await fetchTokenDetailByContract(tokenAddress);
			console.log('fetchTokenInfoByContract', res);
			setTokenDetail(get(res, 'data', null));
		} catch (error) {}
	};

	const showSuccessToast = (hash: string) => {
		toast(
			<div>
				<span>Buy token {tokenAddress} successfully!</span>
				<button
					onClick={() => {
						window.open(
							`${ENV_CONFIGS.EXPLORER_URL}${EXPLORER_TYPES.TX}/${hash}`
						);
					}}
					style={{
						marginLeft: '10px',
						padding: '5px 10px',
						border: 'none',
						background: '#007bff',
						color: '#fff',
						borderRadius: '3px',
						cursor: 'pointer'
					}}
				>
					View on Explorer
				</button>
			</div>,
			{
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			}
		);
	};

	/**
	 * buy token with eth => return token
	 * fake token: 0x668Ff9A7b59a8E007ba8b380efF919D6df563A49
	 * fake: 0.001ETH
	 */
	const handleBuyExactIn = async () => {
		try {
			const amount = 0.001;
			if (!account || !provider) {
				alert('connect wallet');
				return;
			}
			const virtualColReserve: any = await UseGetVirtualCollateralReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualColReserve=====', virtualColReserve);
			const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualTokenReserve=====', virtualTokenReserve);
			const amoutOutAndFee: any = await UseGetAmountOutAndFee({
				amountIn: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
				reserveIn: get(virtualColReserve, '[0]', 0),
				reserveOut: get(virtualTokenReserve, '[0]', 0),
				web3Provider: provider,
				currencyAddress: tokenAddress
			});
			console.log('amoutOutAndFee======', amoutOutAndFee);
			const amountOutMin = get(amoutOutAndFee, '0', 0);
			console.log('amountOutMin===', amountOutMin);
			console.log('1111111', {
				amount: amount,
				amountOutMin,
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			UseBuyExactIn(
				{
					amount: amount,
					amountOutMin,
					tokenAddress: tokenAddress,
					web3Provider: provider,
					account
				},
				async (result: any) => {
					console.log('UseBuyExactIn====', result);
					if (result.status === ACTION_STATUS.BUY_EXACT_IN_SUCCESS) {
						showSuccessToast(result?.data);
					}
					if (result.status === ACTION_STATUS.BUY_EXACT_IN_FAIL) {
					}
				}
			);
		} catch (error) {
			console.log('handleBuyExactIn errorrrrr', error);
		}
	};

	/**
	 * buy token with input token => return eth fee
	 * fake token: 0x668Ff9A7b59a8E007ba8b380efF919D6df563A49
	 * fake: 1 fake token
	 */
	const handleBuyExactOut = async (buyAmount: number) => {
		try {
			if (!account || !provider) {
				alert('connect wallet');
				return;
			}
			const virtualColReserve: any = await UseGetVirtualCollateralReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualColReserve=====', virtualColReserve); //
			const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualTokenReserve=====', virtualTokenReserve);
			const amoutInAndFee = await UseGetAmountInAndFee({
				amountOut: new BigNumber(buyAmount).multipliedBy(10 ** 18).toNumber(),
				reserveIn: virtualColReserve,
				reserveOut: virtualTokenReserve,
				web3Provider: provider,
				currencyAddress: tokenAddress
			});
			console.log('amoutInAndFee===', amoutInAndFee);
			const maxCollateralAmount =
				get(amoutInAndFee, '0', 0) + get(amoutInAndFee, '1', 0);
			const nativeFee = get(amoutInAndFee, '1', 0);
			console.log('nativeFee===', nativeFee);
			console.log(
				'maxCollateralAmount===',
				new BigNumber(maxCollateralAmount).toNumber()
			);

			UseBuyExactOut(
				{
					fee: nativeFee,
					tokenAddress: tokenAddress,
					tokenAmount: buyAmount,
					maxCollateralAmount: new BigNumber(maxCollateralAmount).toNumber(),
					web3Provider: provider,
					account
				},
				async (result: any) => {
					console.log('UseBuyExactOut====', result);
					if (result.status === ACTION_STATUS.BUY_EXACT_OUT_SUCCESS) {
						showSuccessToast(result?.data);
					}
					if (result.status === ACTION_STATUS.BUY_EXACT_OUT_FAIL) {
					}
				}
			);
		} catch (error) {
			console.log('UseBuyExactOut errorrrrr', error);
		}
	};

	/**
	 * ===============SELL ACTION==================
	 */

	/**
	 * buy token with eth => return token
	 * fake token: 0x668Ff9A7b59a8E007ba8b380efF919D6df563A49
	 * fake: 0.001ETH
	 */
	const handleSellExactIn = async () => {
		try {
			const amount = 100;
			if (!account || !provider) {
				alert('connect wallet');
				return;
			}

			const allowance = await getAllowanceMemeToken({
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			console.log('allowanceallowanceallowance===', allowance);

			if (Number(allowance) >= amount) {
				handleSellExactInInternal(amount);
			} else {
				UseMemeTokenApproveCallback(
					{
						amount,
						account,
						web3Provider: provider,
						tokenAddress: tokenAddress
					},
					async (result: any) => {
						console.log('111111111', result);
						if (result.status === ACTION_STATUS.APPROVED) {
							handleSellExactInInternal(amount);
						}
						if (result.status === ACTION_STATUS.APPROVE_FAILS) {
						}
					}
				);
			}
		} catch (error) {
			console.log('handleSellExactIn errorrrrr', error);
		}
	};

	const handleSellExactInInternal = async (amount: number) => {
		const virtualColReserve: any = await UseGetVirtualCollateralReserves({
			currencyAddress: tokenAddress,
			web3Provider: provider
		});
		console.log('virtualColReserve=====reserveOut===', virtualColReserve);
		const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
			currencyAddress: tokenAddress,
			web3Provider: provider
		});
		console.log('virtualTokenReserve=====reserveIn===', virtualTokenReserve);
		const amoutOutAndFee = await UseGetAmountOutAndFee({
			amountIn: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
			reserveIn: new BigNumber(virtualTokenReserve).toNumber(),
			reserveOut: new BigNumber(virtualColReserve).toNumber(),
			web3Provider: provider,
			currencyAddress: tokenAddress
		});
		console.log('amoutOutAndFee======', amoutOutAndFee);
		const amountCollateralMin =
			get(amoutOutAndFee, '0', 0) - get(amoutOutAndFee, '1', 0);
		console.log('amountCollateralMin===', amountCollateralMin);
		console.log('1111111', {
			amount: amount,
			amountCollateralMin: new BigNumber(amountCollateralMin).toNumber(),
			tokenAddress: tokenAddress,
			web3Provider: provider,
			account
		});
		UseSellExactIn(
			{
				tokenAmount: amount,
				amountCollateralMin: new BigNumber(amountCollateralMin).toNumber(),
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			},
			async (result: any) => {
				console.log('UseSellExactIn====', result);
				if (result.status === ACTION_STATUS.SELL_EXACT_IN_SUCCESS) {
					showSuccessToast(result?.data);
				}
				if (result.status === ACTION_STATUS.SELL_EXACT_IN_FAIL) {
				}
			}
		);
	};

	const handleSellExactOut = async () => {
		try {
			if (!account || !provider) {
				alert('connect wallet');
				return;
			}
			const amount = 0.000001;
			const virtualColReserve: any = await UseGetVirtualCollateralReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log(
				'virtualColReserve=====virtualColReserve===',
				virtualColReserve
			);
			const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualTokenReserve=====reserveIn===', virtualTokenReserve);
			const amountInAndFee: any = await UseGetAmountInAndFee({
				amountOut: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
				reserveIn: new BigNumber(virtualTokenReserve).toNumber(),
				reserveOut: new BigNumber(virtualColReserve).toNumber(),
				payment: true,
				web3Provider: provider,
				currencyAddress: tokenAddress
			});
			console.log('amountInAndFee======', amountInAndFee);
			const tokenAmountMax = get(amountInAndFee, '0', 0);
			const allowance = await getAllowanceMemeToken({
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			console.log('allowancea2222llowanceallowance===', allowance);

			if (Number(allowance) >= tokenAmountMax) {
				handleSellExactOutInternal(amount, tokenAmountMax);
			} else {
				UseMemeTokenApproveCallback(
					{
						amount: tokenAmountMax,
						account,
						web3Provider: provider,
						tokenAddress: tokenAddress
					},
					async (result: any) => {
						console.log('111111111', result);
						if (result.status === ACTION_STATUS.APPROVED) {
							handleSellExactOutInternal(amount, tokenAmountMax);
						}
						if (result.status === ACTION_STATUS.APPROVE_FAILS) {
						}
					}
				);
			}
		} catch (error) {
			console.log('handleSellExactOut errorrrrr', error);
		}
	};

	const handleSellExactOutInternal = async (
		amount: number,
		tokenAmountMax: number
	) => {
		try {
			console.log('1111111', {
				amount: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
				tokenAmountMax: new BigNumber(tokenAmountMax).toNumber(),
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			UseSellExactOut(
				{
					tokenAmountMax: new BigNumber(tokenAmountMax).toNumber(),
					amountCollateral: amount,
					tokenAddress: tokenAddress,
					web3Provider: provider,
					account
				},
				async (result: any) => {
					console.log('UseSellExactOut====', result);
					if (result.status === ACTION_STATUS.SELL_EXACT_OUT_SUCCESS) {
						showSuccessToast(result?.data);
					}
					if (result.status === ACTION_STATUS.SELL_EXACT_OUT_FAIL) {
					}
				}
			);
		} catch (error) {
			console.log('handleSellExactOut errorrrrr', error);
		}
	};

	return (
		<div className="token-detail">
			<div className="left-panel">
				<WatchPanel />
				<TrendingPanel />
			</div>
			<div className="content">
				<CandlestickChart socketData={tokenPrice} tokenAddress={tokenAddress} />
				<ActivityPanel />
			</div>
			<div className="right-panel">
				<QuickLinks />
				<ConnectTGBot />
				<BuyPanel tokenDetail={tokenDetail} />
				<QuickView />
				<Vote />
				<UpdateToken />
				<PoolInfo />
				<Audit />
			</div>
		</div>
	);
};
export default TokenPage;
