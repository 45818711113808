import './index.scss';
const QuickLinks = () => {
	return (
		<div className="quick-links">
			<a
				className="item"
				href="https://x.com/search?q=$iris"
				target="_blank"
				rel="noreferrer"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					fill="currentColor"
					viewBox="0 0 20 20"
				>
					<path d="M9.213 1.988a7.14 7.14 0 017.135 7.234c-.035 3.922-3.28 7.111-7.203 7.082-3.985-.03-7.181-3.276-7.14-7.25.042-3.933 3.253-7.081 7.208-7.066zm-.058 12.61a5.473 5.473 0 005.508-5.412c.04-3.025-2.465-5.536-5.51-5.524-3.007.012-5.45 2.467-5.45 5.476a5.455 5.455 0 005.452 5.46z"></path>
					<path d="M16.666 17.795l-1.24-1.24a.75.75 0 010-1.056l.055-.055a.749.749 0 011.056 0l1.24 1.24a.75.75 0 010 1.057l-.054.054a.75.75 0 01-1.057 0z"></path>
				</svg>
				<span>Name</span>
			</a>

			<a
				className="item"
				href="https://x.com/search?q=$iris"
				target="_blank"
				rel="noreferrer"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					fill="currentColor"
					viewBox="0 0 20 20"
				>
					<path d="M9.213 1.988a7.14 7.14 0 017.135 7.234c-.035 3.922-3.28 7.111-7.203 7.082-3.985-.03-7.181-3.276-7.14-7.25.042-3.933 3.253-7.081 7.208-7.066zm-.058 12.61a5.473 5.473 0 005.508-5.412c.04-3.025-2.465-5.536-5.51-5.524-3.007.012-5.45 2.467-5.45 5.476a5.455 5.455 0 005.452 5.46z"></path>
					<path d="M16.666 17.795l-1.24-1.24a.75.75 0 010-1.056l.055-.055a.749.749 0 011.056 0l1.24 1.24a.75.75 0 010 1.057l-.054.054a.75.75 0 01-1.057 0z"></path>
				</svg>
				<span>CA</span>
			</a>

			<a
				className="item"
				href="https://google.com"
				target="_blank"
				rel="noreferrer"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					fill="currentColor"
					viewBox="0 0 20 20"
				>
					<g clipPath="url(#clip0_1553_2200)">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM6.446 2.831A8.037 8.037 0 003.07 6h2.323c.212-1.023.505-1.96.865-2.77.06-.136.123-.269.188-.399zM2 10c0-.69.088-1.36.252-2h2.842a21.008 21.008 0 000 4H2.252A8.013 8.013 0 012 10zm1.07 4a8.037 8.037 0 003.376 3.169 9.877 9.877 0 01-.188-.399c-.36-.81-.653-1.747-.865-2.77H3.07zm4.372 0c.173.732.392 1.392.643 1.958.328.738.693 1.273 1.047 1.61.35.333.641.432.868.432.227 0 .518-.1.867-.432.355-.337.72-.872 1.048-1.61.251-.566.47-1.226.643-1.958H7.442zm7.165 0a13.716 13.716 0 01-.865 2.77c-.06.136-.123.269-.188.399A8.037 8.037 0 0016.93 14h-2.323zm3.14-2h-2.841a21.027 21.027 0 000-4h2.842c.165.64.252 1.31.252 2s-.087 1.36-.252 2zm-4.851 0H7.104A18.907 18.907 0 017 10c0-.693.037-1.362.104-2h5.792c.067.638.104 1.307.104 2 0 .693-.037 1.362-.104 2zm1.71-6h2.324a8.037 8.037 0 00-3.376-3.169c.065.13.128.263.188.399.36.81.653 1.747.865 2.77zm-6.52-1.958c-.252.566-.47 1.226-.644 1.958h5.116a11.248 11.248 0 00-.643-1.958c-.328-.738-.693-1.273-1.047-1.61C10.518 2.099 10.226 2 10 2c-.227 0-.518.1-.868.432-.354.337-.719.872-1.047 1.61z"
						></path>
					</g>
					<defs>
						<clipPath id="clip0_1553_2200">
							<rect width="20" height="20"></rect>
						</clipPath>
					</defs>
				</svg>
			</a>

			<a
				className="item"
				href="https://google.com"
				target="_blank"
				rel="noreferrer"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					fill="currentColor"
					viewBox="0 0 12 12"
				>
					<g clipPath="url(#clip0_7920_513)">
						<path d="M9.282 1h1.71L7.255 5.27l4.394 5.809H8.21L5.515 7.555 2.43 11.08H.721l3.995-4.567L.5 1h3.528l2.436 3.22L9.282 1zm-.6 9.056h.947L3.513 1.97H2.497l6.185 8.086z"></path>
					</g>
					<defs>
						<clipPath id="clip0_7920_513">
							<rect width="12" height="12"></rect>
						</clipPath>
					</defs>
				</svg>
			</a>

			<a
				className="item"
				href="https://google.com"
				target="_blank"
				rel="noreferrer"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					fill="currentColor"
					viewBox="0 0 12 12"
				>
					<g clipPath="url(#clip0_7920_515)">
						<path d="M11.894 1.91l-1.8 8.487c-.134.6-.49.746-.992.465L6.36 8.842l-1.322 1.273c-.147.147-.27.27-.551.27l.196-2.793L9.764 3c.22-.196-.05-.307-.344-.11L3.138 6.844.43 6c-.588-.183-.6-.588.122-.869l10.582-4.078c.49-.183.918.11.76.857z"></path>
					</g>
					<defs>
						<clipPath id="clip0_7920_515">
							<rect width="12" height="12"></rect>
						</clipPath>
					</defs>
				</svg>
			</a>

			<a
				className="item"
				href="https://google.com"
				target="_blank"
				rel="noreferrer"
			>
				<svg
					className="icon"
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="14"
					fill="#FFD039"
					viewBox="0 0 14 14"
				>
					<g clipPath="url(#clip0_9436_2400)">
						<path d="M3.252 4.28L1.75 5.784l1.75 1.75 1.75-1.75-1.503-1.502a.35.35 0 00-.495 0z"></path>
						<path d="M10.253 4.28L8.75 5.784l1.75 1.75 1.75-1.75-1.502-1.502a.35.35 0 00-.495 0z"></path>
						<path d="M.488 4.516c-.117-.66.741-1.023 1.132-.478L2.9 5.819a.56.56 0 00.894.02l2.712-3.432a.63.63 0 01.989 0l2.711 3.433a.56.56 0 00.894-.02l1.28-1.782c.391-.545 1.25-.183 1.132.478l-1.056 5.911a1.4 1.4 0 01-1.378 1.154H2.922a1.4 1.4 0 01-1.378-1.154L.488 4.516z"></path>
					</g>
					<defs>
						<clipPath id="clip0_9436_2400">
							<rect width="14" height="14"></rect>
						</clipPath>
					</defs>
				</svg>
				<svg className="text" viewBox="0 0 45.151947021484375 17">
					<defs>
						<linearGradient
							id="gridientfb4e752e51d7dd87"
							x1="0"
							y1="0"
							x2="100%"
							y2="0"
						>
							<stop offset="26.87%" stopColor="#FFCD1C"></stop>
							<stop offset="64.85%" stopColor="#FF41E1"></stop>
						</linearGradient>
					</defs>
					<text
						x="50%"
						y="50%"
						dy="0.3em"
						textAnchor="middle"
						fill="url(#gridientfb4e752e51d7dd87)"
						fontSize="12px"
						fontWeight="500"
					>
						<tspan>Update</tspan>
					</text>
				</svg>
			</a>
		</div>
	);
};
export default QuickLinks;
