import { put } from 'redux-saga/effects';
import { cloneDeep, find, get } from 'lodash';
import { getUserInfo } from '../services/user';
import {
	GET_HOT_LIST_TOKEN_SUCCESS,
	GET_NEW_TOKEN_SUCCESS,
	GET_TOP_TOKEN_SUCCESS,
	GET_USER_INFO_SUCCESS,
	GET_USER_LIST_TOKEN_SUCCESS
} from '../actions/user';
import {
	fetchHotTokens,
	fetchNewTokens,
	fetchTopTokens,
	fetchUserTokens
} from '../services/token';

export function* handleGetUserInfo(action: any): any {
	try {
		const res = yield getUserInfo();
		yield put({
			type: GET_USER_INFO_SUCCESS,
			payload: res?.data
		});
	} catch (error) {}
}

export function* handleGetUserListToken(action: any): any {
	try {
		const res = yield fetchUserTokens();
		yield put({
			type: GET_USER_LIST_TOKEN_SUCCESS,
			payload: res?.data
		});
	} catch (error) {}
}

export function* handleGetHotListToken(action: any): any {
	try {
		const res = yield fetchHotTokens();
		yield put({
			type: GET_HOT_LIST_TOKEN_SUCCESS,
			payload: res?.data
		});
	} catch (error) {}
}

export function* handleGetNewListToken(action: any): any {
	try {
		const res = yield fetchNewTokens();
		yield put({
			type: GET_NEW_TOKEN_SUCCESS,
			payload: res?.data
		});
	} catch (error) {}
}

export function* handleGetTopListToken(action: any): any {
	try {
		const res = yield fetchTopTokens();
		yield put({
			type: GET_TOP_TOKEN_SUCCESS,
			payload: res?.data
		});
	} catch (error) {}
}
