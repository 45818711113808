import './index.scss';
const UpdateToken = () => {
	return (
		<a
			href="https://gmgn.ai/verifytoken?address=AqeyxvWQ5CNcbiUGWq1KKd1ZrYdr3KmTqnwWoHYGpump"
			target="_blank"
			rel="noreferrer"
			className="update-token"
		>
			<div className="update-token-header">
				Shill Your{' '}
				<svg
					width="100%"
					viewBox="0 0 91.1239013671875 20"
					style={{ width: '91.1239px', height: 'auto' }}
				>
					<defs>
						<linearGradient
							id="gridientb26434b0f77ffe91"
							x1="0"
							y1="0"
							x2="100%"
							y2="0"
						>
							<stop offset="26.87%" stopColor="#FFCD1C"></stop>
							<stop offset="64.85%" stopColor="#FF41E1"></stop>
						</linearGradient>
					</defs>
					<text
						x="50%"
						y="50%"
						dy="0.3em"
						textAnchor="middle"
						fill="url(#gridientb26434b0f77ffe91)"
						fontSize="14"
						fontWeight="600"
					>
						<tspan>Token Profile</tspan>
					</text>
				</svg>{' '}
				On GMGN.AI
			</div>
			<div className='update-token-action'>
				Update Token
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16px"
					height="16px"
					fill="currentColor"
					viewBox="0 0 12 12"
				>
					<path d="M6.721 1.643a.5.5 0 00-.006.707L9.808 5.5H1a.5.5 0 000 1h8.808L6.715 9.65a.5.5 0 00.713.7l3.929-4a.5.5 0 000-.7l-3.929-4a.5.5 0 00-.707-.007z"></path>
				</svg>
			</div>
		</a>
	);
};
export default UpdateToken;
