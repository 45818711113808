import axios from 'axios';
import { ENDPOINTS } from '../../constants';
import { request } from '../../libs/client';

export const fetchListNftInMarket = () => {
	return request(ENDPOINTS.LIST_NFT_MARKET, { method: 'GET' });
};

export const fetchNftDetail = ({ collectionAddress, nftId }: any) => {
	return request(`${ENDPOINTS.NFT_DETAIL}/${collectionAddress}/${nftId}`, {
		method: 'GET'
	});
};

export const fetchUserNft = (account: string) => {
	return request(`${ENDPOINTS.NFT_OWNER}/${account}`, { method: 'GET' });
};

export const fetchNFTInCollection = (collectionAddress: string) => {
	return request(`${ENDPOINTS.NFT_COLLECTION}/${collectionAddress}`, {
		method: 'GET'
	});
};

export const fetchUserTokens = () => {
	return request(`${ENDPOINTS.USER_MY_TOKEN}`, {
		method: 'GET'
	});
};
export const fetchTokenDetailByContract = (contract: string) => {
	return request(`${ENDPOINTS.TOKEN_CONTRACT}/${contract}`, {
		method: 'GET'
	});
};
export const fetchHotTokens = () => {
	return request(`${ENDPOINTS.TOKEN_HOT}`, {
		method: 'GET'
	});
};
export const fetchNewTokens = () => {
	return request(`${ENDPOINTS.TOKEN_NEW}`, {
		method: 'GET'
	});
};
export const fetchTopTokens = () => {
	return request(`${ENDPOINTS.TOKEN_TOP}`, {
		method: 'GET'
	});
};

export const requestCreateToken = ({
	name,
	symbol,
	creator,
	transaction_id,
	chain_id,
	decimals,
	logo_url,
	description,
	website,
	telegram,
	twitter
}: any) => {
	return request(`${ENDPOINTS.CREATE_TOKEN}`, {
		method: 'POST',
		body: {
			name,
			symbol,
			creator,
			transaction_id,
			chain_id,
			decimals,
			logo_url,
			description,
			website,
			telegram,
			twitter
		}
	});
};

export const fetchTokenPriceHistory = (
	tokenAddress: string,
	startDate: string,
	endDate: string,
	interval: number
) => {
	return request(
		`${ENDPOINTS.TOKEN_PRICE}?tokenAddress=${tokenAddress}&startDate=${startDate}&endDate=${endDate}&interval=${interval}`,
		{
			method: 'GET'
		}
	);
};
