import { combineReducers } from 'redux';
import walletReducer from './wallet';
import userReducer from './user';
import tokenReducer from './token';

const rootReducer = combineReducers({
	wallet: walletReducer,
	user: userReducer,
	token: tokenReducer
});

export default rootReducer;
