import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_URLS, STORAGE_KEY } from '../constants';

const JoinAddressPage = () => {
	const params = useParams();
	const navigate = useNavigate();
	console.log('paramsssss', params);
	useEffect(() => {
		if (params && params?.referral) {
			localStorage.setItem(STORAGE_KEY.REFERRAL_ID, params?.referral);
		}
		navigate(PAGE_URLS.DASHBOARD);
	}, [params]);
	return <div className="mm-page"></div>;
};
export default JoinAddressPage;
