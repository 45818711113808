import { Button, Col, Row } from 'react-bootstrap';
import ReferralChartCard from '../components/referral-chart-card';
import ReferralCTACard from '../components/referral-cta-card';

const ReferralPage = () => {
	return (
		<div className="mm-page">
			<Row className="gx-lg-5 gy-4 gy-lg-0">
				<Col lg="9" className="order-lg-1 order-2">
					<div className="mm-page-header">
						<div className="d-flex align-items-center flex-wrap gap-2">
							<h3 className="mb-0 text-white">Rewards</h3>
							<Button variant="warning" size="sm">
								<span>10% Commission Rate</span>
							</Button>
							<Button variant="secondary" size="sm">
								<span>Apply to increase rebate</span>
							</Button>
						</div>
						<Button variant="outline-secondary" size="sm">
							<img height={14} src="/assets/images/icons/telegram.svg" alt="" />
							<span className="mx-1">TGBot rewards</span>
							<img
								height={12}
								src="/assets/images/icons/icon-right.svg"
								alt=""
							/>
						</Button>
					</div>
					<ReferralChartCard />
				</Col>

				<Col lg="3" className="order-lg-2 order-1">
					<ReferralCTACard tutorialLink="#" connectTGLink="#" />
				</Col>
			</Row>
		</div>
	);
};
export default ReferralPage;
