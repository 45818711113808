import { Button, Dropdown, Form } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import './index.scss';
import { PAGE_URLS } from '../../constants';
import { useWeb3React } from '@web3-react/core';
import { useEthBalance, useUsdcBalance } from '../../hooks/useState';
import {
	formatNumberDownRound,
	getFullDisplayBalance
} from '../../utils/number';
import { useState } from 'react';
import { minimizeAddress } from '../../utils/string';
export interface IHeaderMenuItem {
	text: string;
	link: string;
}
export interface IHeaderUserInfo {
	avatar: string;
	balance: number;
	balanceSymbol: string;
	walletAddress: string;
}
export type HeaderProps = {
	menus: IHeaderMenuItem[];
	userInfo?: IHeaderUserInfo;
	onMenuItemClick?: (menu: IHeaderMenuItem) => void;
	onStartNewCoin: () => void;
	onConnect: () => void;
	onCopyWalletAddress: () => void;
	onSwitchLogin: () => void;
	onDisconnect: () => void;
};
const Header = ({
	menus,
	userInfo,
	onMenuItemClick = () => {},
	onStartNewCoin,
	onConnect,
	onCopyWalletAddress,
	onSwitchLogin,
	onDisconnect
}: HeaderProps) => {
	const { account, chainId, provider } = useWeb3React();
	const ethBal = useEthBalance();
	const [copied, setCopied] = useState<boolean>(false);

	const handleCopyAddress = () => {
		// e.stopPropagation();
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	return (
		<div className="mm-header">
			<ul>
				{menus.map(menu => (
					<li key={menu.link}>
						<NavLink
							className={({ isActive }) => (isActive ? 'active' : '')}
							to={menu.link}
							onClick={() => onMenuItemClick(menu)}
						>
							{menu.text}
						</NavLink>
					</li>
				))}
			</ul>
			<form className="search">
				<img src="/assets/images/icons/icon-search.svg" alt="" />
				<Form.Control size="sm" placeholder="Search token/contract/wallet" />
			</form>
			<div className="price">
				<span>SOL</span>
				<b>$245.95</b>
			</div>

			{!account ? (
				<Button
					variant="warning"
					className="connect"
					size="sm"
					onClick={onConnect}
				>
					Connect
				</Button>
			) : (
				<>
					<Button
						variant="warning"
						className="connect"
						size="sm"
						onClick={onStartNewCoin}
					>
						Start new coin
					</Button>
					<Dropdown className="user">
						<Dropdown.Toggle as="div" variant="text" id="dropdown-basic">
							<div className="avatar">
								{/* <img src={userInfo?.avatar} alt={account} /> */}
								<img
									src={
										'https://cdn-icons-png.flaticon.com/512/8345/8345328.png'
									}
									alt={account}
								/>
							</div>
							<div className="info">
								<div className="balance">
									{/* <img src={userInfo?.balanceSymbol} alt="" /> */}
									<img
										src={
											'https://www.iconarchive.com/download/i109534/cjdowner/cryptocurrency-flat/Ethereum-ETH.1024.png'
										}
										width={12}
										height={12}
										alt=""
									/>
									<span>{getFullDisplayBalance(ethBal)} ETH</span>
								</div>
								<div className="address">
									<span>{minimizeAddress(account)}</span>
									{/* <button type="button" onClick={onCopyWalletAddress}> */}
									<CopyToClipboard text={account} onCopy={handleCopyAddress}>
										<img src="/assets/images/icons/icon-copy.svg" alt="" />
									</CopyToClipboard>
									{/* </button> */}
								</div>
							</div>
						</Dropdown.Toggle>

						<Dropdown.Menu align="end">
							<Dropdown.Item onClick={onSwitchLogin}>
								<img src="/assets/images/icons/telegram.svg" alt="" />
								<span>Switch TG login</span>
								<span>Connect</span>
							</Dropdown.Item>
							<Dropdown.Item as={Link} to={PAGE_URLS.MY_WALLET}>
								My Wallet
							</Dropdown.Item>
							<Dropdown.Item as={Link} to={PAGE_URLS.REFERRAL}>
								Referral
							</Dropdown.Item>
							<Dropdown.Item
								as="a"
								target="_blank"
								href={PAGE_URLS.TG_ALERT_TUTORIAL}
							>
								TG Alert Tutorial
							</Dropdown.Item>
							<Dropdown.Item
								as="a"
								target="_blank"
								href={PAGE_URLS.ADD_TWITTER}
							>
								Add Twitter
							</Dropdown.Item>
							<Dropdown.Item onClick={onDisconnect} className="text-danger">
								Disconnect
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</>
			)}
		</div>
	);
};
export default Header;
