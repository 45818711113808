import './index.scss';
const Vote = () => {
	return (
		<div className="vote">
			<span>Vote</span>
			<div className='values'>
				<div className='value'>
					<img src='/assets/images/icons/vote2.png' alt=''/>
					<span>50%</span>
				</div>
				<div className='percent'>
					<span className='green' style={{width:'50%'}}></span>
					<span className='red' style={{width:'50%'}}></span>
				</div>
				<div className='value'>
					<img src='/assets/images/icons/vote1.png' alt=''/>
					<span>50%</span>
				</div>
			</div>
		</div>
	);
};
export default Vote;
