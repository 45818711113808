import { AgGridReact } from 'ag-grid-react';
import { Tab, Tabs } from 'react-bootstrap';
import './index.scss';
import TokenItem from './token';
import { useMemo } from 'react';
import TokenPriceItem from './token-price';
import { useHotToken } from '../../../hooks/useState';
const TrendingPanel = () => {
	const hotTokens = useHotToken();

	const COLUMN: any[] = [
		{
			field: 'id',
			headerName: 'Token',
			flex: 1,
			sortable: true,
			unSortIcon: true,
			cellRenderer: 'tokenItemRenderer'
		},
		{
			field: 'volume',
			headerName: 'Volume',
			maxWidth: 130,
			sortable: true,
			unSortIcon: true,
			cellRenderer: 'tokenPriceItemRenderer'
		}
	];
	const components = useMemo<{
		[p: string]: any;
	}>(() => {
		return {
			tokenItemRenderer: TokenItem,
			tokenPriceItemRenderer: TokenPriceItem
		};
	}, []);
	return (
		<div className="trending-panel">
			<Tabs defaultActiveKey="trending" id="uncontrolled-tab-example">
				<Tab eventKey="trending" title="Trending">
					<div className="filters">
						<button type="button" className="active">
							1m
						</button>
						<button type="button">5m</button>
						<button type="button">1h</button>
						<button type="button">6h</button>
						<button type="button">24h</button>
					</div>
					<AgGridReact
						className="ag-theme-quartz ag-theme-quartz-dark"
						rowData={hotTokens}
						columnDefs={COLUMN}
						rowHeight={47}
						domLayout="autoHeight"
						components={components}
					/>
				</Tab>
				<Tab eventKey="pump" title="Pump">
					<div className="filters">
						<button type="button" className="active">
							1m
						</button>
						<button type="button">5m</button>
						<button type="button">1h</button>
						<button type="button">6h</button>
						<button type="button">24h</button>
					</div>
					<AgGridReact
						className="ag-theme-quartz ag-theme-quartz-dark"
						rowData={hotTokens}
						columnDefs={COLUMN}
						rowHeight={48}
						domLayout="autoHeight"
						components={components}
					/>
				</Tab>
			</Tabs>
		</div>
	);
};
export default TrendingPanel;
