import { CustomCellRendererProps } from 'ag-grid-react';
import './cell-maker.scss';
const ActivityCellMaker = (props: CustomCellRendererProps) => {
	return (
		<div className="activity-cell-maker">
			<div className="top">
				{props.data.makerHighlight ? <span>✨</span> : <></>}
				<span>{props.data.maker}</span>
				<button type="button">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="14px"
						height="14px"
						fill="#9A9FAA"
						viewBox="0 0 14 14"
					>
						<path d="M7.572 3.558l2.625 2.695L3.625 13H1v-2.695l6.572-6.747zm.75-.77l1.515-1.555a.75.75 0 011.075 0l1.578 1.62a.75.75 0 010 1.046l-1.543 1.585-2.625-2.695zM7.308 11.5h4.939a.75.75 0 110 1.5h-5.69a.44.44 0 01-.31-.75l.53-.53a.749.749 0 01.531-.22z"></path>
					</svg>
				</button>
				<span className="chip">{props.data.makerCount}</span>
			</div>
			<div className="status">
				<div
					className="percent"
					style={{ width: `${props.data.makerPercent}%` }}
				></div>
			</div>
		</div>
	);
};
export default ActivityCellMaker;
