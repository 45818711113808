import { createChart } from 'lightweight-charts';
import { get } from 'lodash';
import { useEffect, useRef } from 'react';
import { useActiveWeb3React } from '../../../hooks';
import { getPriceToken } from '../../../hooks/useMemeToken';
import { fetchTokenPriceHistory } from '../../../redux/services/token';
import './index.scss';
import { data } from 'react-router-dom';

interface ICandlestickChart {
	tokenAddress: string;
	socketData: any;
}

const CandlestickChart = ({ tokenAddress, socketData }: ICandlestickChart) => {
	const chartContainerRef = useRef(null);
	const chartRef = useRef(null); // Reference to the chart
	const candlestickSeriesRef = useRef(null); // Reference to the candlestick series

	useEffect(() => {
		// 1. Create Chart
		const chart = createChart(chartContainerRef.current, {
			width: chartContainerRef.current.clientWidth,
			height: 412,
			layout: {
				background: { color: '#14151794' },
				textColor: '#d1d4dc'
			},
			grid: {
				vertLines: { color: '#2B2B43' },
				horzLines: { color: '#2B2B43' }
			},
			timeScale: { timeVisible: true, secondsVisible: false }
		});

		// 2. Add Candlestick Series
		const candlestickSeries = chart.addCandlestickSeries({
			upColor: '#4caf50',
			downColor: '#f44336',
			borderVisible: false,
			wickUpColor: '#4caf50',
			wickDownColor: '#f44336'
		});

		chartRef.current = chart;
		candlestickSeriesRef.current = candlestickSeries;

		// 3. Fetch Initial Data
		const fetchData = async () => {
			try {
				const now = new Date();
				const formattedDate = encodeURIComponent(now.toISOString());
				const res = await fetchTokenPriceHistory(
					tokenAddress,
					'2024-06-01T06%3A17%3A55.722Z',
					formattedDate,
					3600
				);
				const data = get(res, 'data', []);

				// Format data to Lightweight Charts' structure
				const formattedData = data.map(item => ({
					time: Math.floor(new Date(item?.timestamp).getTime() / 1000), // Binance returns timestamp in ms
					open: parseFloat(item?.open),
					high: parseFloat(item?.high),
					low: parseFloat(item?.low),
					close: parseFloat(item?.close)
				}));

				// Set initial data
				candlestickSeries.setData(formattedData);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();

		// 4. Simulate Real-Time Data Updates
		// const interval = setInterval(async () => {
		// 	const now = Math.floor(Date.now() / 1000);
		// 	const price = await getPriceToken({
		// 		tokenAddress: tokenAddress
		// 	});
		// 	const newCandle: any = {
		// 		time: now,
		// 		open: price,
		// 		high: price,
		// 		low: price,
		// 		close: price
		// 	};

		// 	// Update the chart with new data
		// 	candlestickSeries.update(newCandle);
		// }, 5000); // Add new data every 5 seconds

		// 5. Clean up on unmount
		return () => {
			// clearInterval(interval);
			chart.remove();
		};
	}, []);

	useEffect(() => {
		if (socketData && candlestickSeriesRef.current) {
			const now = Math.floor(Date.now() / 1000);
			const price = data;
			const newCandle: any = {
				time: now,
				open: price,
				high: price,
				low: price,
				close: price
			};

			candlestickSeriesRef.current.update(newCandle);
		}
	}, [socketData]);

	return (
		<div
			className="trade-view"
			ref={chartContainerRef}
			style={{ position: 'relative', width: '100%' }}
		/>
	);
};

export default CandlestickChart;
