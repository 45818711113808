import { ReactNode } from 'react';
import './index.scss';

export interface AutoBoxProps {
	children: ReactNode;
}
const AutoBox = ({ children }: AutoBoxProps) => {
	return (
		<div className="auto-panel">
			<div className="top">
				<img src="https://gmgn.ai/static/logo_big.svg" alt="" />
				<div className="icon">
					<span></span>
					<span></span>
					<span></span>
				</div>
				<img src="/assets/images/icons/telegram.svg" alt="" />
			</div>
			<p>{children}</p>
			<button type="button" className="btn btn-light w-100">
				Connect Telegram
			</button>
		</div>
	);
};
export default AutoBox;
