import { all, takeLatest } from 'redux-saga/effects';
import { REQUEST_GET_WALLET_NFT_LIST } from '../actions/wallet';
import { handleFetchListWalletNfts } from './wallet';
import {
	handleGetHotListToken,
	handleGetNewListToken,
	handleGetTopListToken,
	handleGetUserInfo,
	handleGetUserListToken
} from './user';
import {
	GET_HOT_LIST_TOKEN_REQUEST,
	GET_NEW_TOKEN_REQUEST,
	GET_TOP_TOKEN_REQUEST,
	GET_USER_INFO_REQUEST,
	GET_USER_LIST_TOKEN_REQUEST
} from '../actions/user';

export function* rootSaga() {
	yield all([
		takeLatest(REQUEST_GET_WALLET_NFT_LIST, handleFetchListWalletNfts)
	]);
	yield all([takeLatest(GET_USER_INFO_REQUEST, handleGetUserInfo)]);
	yield all([takeLatest(GET_USER_LIST_TOKEN_REQUEST, handleGetUserListToken)]);
	yield all([takeLatest(GET_HOT_LIST_TOKEN_REQUEST, handleGetHotListToken)]);
	yield all([takeLatest(GET_NEW_TOKEN_REQUEST, handleGetNewListToken)]);
	yield all([takeLatest(GET_TOP_TOKEN_REQUEST, handleGetTopListToken)]);
}
