export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';

export const GET_USER_LIST_TOKEN_REQUEST = 'GET_USER_LIST_TOKEN_REQUEST';
export const GET_USER_LIST_TOKEN_SUCCESS = 'GET_USER_LIST_TOKEN_SUCCESS';
export const GET_USER_LIST_TOKEN_FAIL = 'GET_USER_LIST_TOKEN_FAIL';

export const GET_HOT_LIST_TOKEN_REQUEST = 'GET_HOT_LIST_TOKEN_REQUEST';
export const GET_HOT_LIST_TOKEN_SUCCESS = 'GET_HOT_LIST_TOKEN_SUCCESS';
export const GET_HOT_LIST_TOKEN_FAIL = 'GET_HOT_LIST_TOKEN_FAIL';

export const GET_NEW_TOKEN_REQUEST = 'GET_NEW_TOKEN_REQUEST';
export const GET_NEW_TOKEN_SUCCESS = 'GET_NEW_TOKEN_SUCCESS';
export const GET_NEW_TOKEN_FAIL = 'GET_NEW_TOKEN_FAIL';

export const GET_TOP_TOKEN_REQUEST = 'GET_TOP_TOKEN_REQUEST';
export const GET_TOP_TOKEN_SUCCESS = 'GET_TOP_TOKEN_SUCCESS';
export const GET_TOP_TOKEN_FAIL = 'GET_TOP_TOKEN_FAIL';
