import { useMemo, useState } from 'react';
import { FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './index.scss';
import { AgGridReact } from 'ag-grid-react';
import ActivityCellType from './cell-type';
import ActivityCellAction from './cell-action';
import ActivityCellMaker from './cell-maker';
const ActivityPanel = () => {
	const TABS = [
		{ key: 'Activity', title: 'Activity' },
		{ key: 'Traders', title: 'Traders' },
		{
			key: 'Holders',
			title: (
				<>
					Holders(2,365){' '}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="14px"
						height="14px"
						fill="currentColor"
						viewBox="0 0 20 20"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M15.5 8a3.5 3.5 0 10-3.463-2.988 2.953 2.953 0 00-.1-.012C7.844 5 6.274 7.972 6 9.457c.379.46 1.49 1.495 2.905 1.947-1.099 1.254-1.56 4.194-1.71 6.275-1.042-.093-2.369-.287-3.614-.653-.677-.2-1.047-.599-1.247-1.116-.211-.546-.242-1.248-.15-2.021.117-1.003.432-1.897.696-2.648.056-.158.11-.31.158-.456.08-.236.149-.44.198-.604.233-.779-.11-1.498-.532-1.914-.408-.404-1.243-.744-1.638-.744v.986c.268.086.65.274.914.537.253.25.356.51.262.823-.038.126-.096.298-.166.506-.073.215-.176.476-.291.768-.326.828-.752 1.905-.867 2.893-.1.843-.148 1.732.148 2.497.307.793 1.1 1.44 2.17 1.884 1.711.595 3.153.595 4.09.595h.011L7.349 19h9.64c.045-.268.026-6.977-1.804-11.014.104.01.209.014.315.014zM11 8a1 1 0 11-2 0 1 1 0 012 0z"
						></path>
						<path d="M11 4c0 .039 0 .078-.002.116-2.138.334-3.601 1.338-4.553 2.45A3 3 0 1111 4z"></path>
					</svg>{' '}
					2.99%
				</>
			)
		},
		{ key: 'BC Owners', title: 'BC Owners (3)' },
		{ key: 'Positions', title: 'Positions(0)' }
	];
	const SUBTABS = [
		{ key: 'All', title: 'All' },
		{ key: 'Smart', title: 'Smart' },
		{ key: 'KOLVC', title: 'KOL/VC' },
		{ key: 'Whale', title: 'Whale(4)' },
		{ key: 'Fresh', title: 'Fresh' },
		{ key: 'Snipers', title: 'Snipers(3)' },
		{ key: 'Top', title: 'Top(42)' },
		{ key: 'DEV', title: 'DEV(4)' },
		{ key: 'Following', title: 'Following' },
		{ key: 'Insiders', title: 'Insiders(61)' }
	];
	const DATA = [
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Buy',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: true,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Sell',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: false,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Sell',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: false,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Sell',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: false,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Buy',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: true,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Sell',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: false,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Buy',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: true,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Sell',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: false,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Buy',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: true,
			makerCount: 1,
			makerPercent: 60
		},
		{
			id: 1,
			time: '1m',
			datetime: '12/17 10:55',
			type: 'Sell',
			totalUSD: '$2.14',
			amount: '9.4B',
			price: '$0.0₉22807',
			maker: '6J9nJ...qN9',
			makerHighlight: false,
			makerCount: 1,
			makerPercent: 60
		}
	];
	const COLUMN: any[] = [
		{
			field: 'time',
			headerName: 'Time',
			flex: 1,
			sortable: true,
			unSortIcon: true,
			cellStyle: { fontSize: '14px' },
			filter: 'agNumberColumnFilter',
			cellRenderer: params => (
				<OverlayTrigger
					placement="bottom"
					overlay={
						<Tooltip id={`activity-time-${params.data.id}`}>
							{params.data.datetime}
						</Tooltip>
					}
				>
					<span>{params.data.time}</span>
				</OverlayTrigger>
			)
		},
		{
			field: 'type',
			headerName: 'Type',
			cellRenderer: 'activityCellTypeRenderer',
			filter: 'agTextColumnFilter',
			maxWidth: 100,
			minWidth: 100
		},
		{
			field: 'totalUSD',
			headerName: 'Total USD',
			cellStyle: { fontWeight: 'bold', color: '#afdfb6', fontSize: '14px' },
			cellClass: params => {
				return params.data.type === 'Buy' ? 'green' : 'red';
			},
			filter: 'agNumberColumnFilter'
		},
		{
			field: 'amount',
			headerName: 'Amount',
			cellStyle: { fontWeight: 'bold', color: '#afdfb6', fontSize: '14px' },
			cellClass: params => {
				return params.data.type === 'Buy' ? 'green' : 'red';
			}
		},
		{
			field: 'price',
			headerName: 'Price',
			sortable: true,
			unSortIcon: true,
			cellStyle: { fontWeight: 'bold', color: '#afdfb6', fontSize: '14px' },
			cellClass: params => {
				return params.data.type === 'Buy' ? 'green' : 'red';
			}
		},
		{
			field: 'maker',
			headerName: 'Maker',
			maxWidth: 200,
			minWidth: 200,
			cellRenderer: 'activityCellMakerRenderer',
			filter: 'agTextColumnFilter'
		},
		{
			field: 'action',
			headerName: '',
			maxWidth: 130,
			minWidth: 130,
			cellRenderer: 'activityCellActionRenderer'
		}
	];
	const components = useMemo<{
		[p: string]: any;
	}>(() => {
		return {
			activityCellTypeRenderer: ActivityCellType,
			activityCellActionRenderer: ActivityCellAction,
			activityCellMakerRenderer: ActivityCellMaker
		};
	}, []);
	const [activeTab, setActiveTab] = useState(TABS[0].key);
	const [activeSubTab, setActiveSubTab] = useState(SUBTABS[0].key);
	const handleTabChange = (newTab: string) => {
		setActiveTab(newTab);
	};
	const handleSubTabChange = (newTab: string) => {
		setActiveSubTab(newTab);
	};
	return (
		<div className="activity-panel">
			<div className="activity-panel-header">
				<div className="tabs">
					{TABS.map(tab => (
						<button
							key={tab.key}
							type="button"
							onClick={() => handleTabChange(tab.key)}
							className={tab.key === activeTab ? 'active' : ''}
						>
							{tab.title}
						</button>
					))}
				</div>
				<FormCheck type="switch" id="one-click-switch" label="One Click" />
				<button type="button" className="active">
					Bubble
				</button>
			</div>

			<div className="activity-panel-subtabs">
				{SUBTABS.map(tab => (
					<button
						key={tab.key}
						type="button"
						onClick={() => handleSubTabChange(tab.key)}
						className={tab.key === activeSubTab ? 'active' : ''}
					>
						{tab.title}
					</button>
				))}
				<FormCheck
					type="checkbox"
					id="filter-bot-checkbox"
					label="Filter Bot"
				/>
				<div className="paused">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="10px"
						height="10px"
						fill="currentColor"
						viewBox="0 0 14 14"
					>
						<rect x="1.012" y="1.012" width="3.992" height="11.975"></rect>
						<rect x="8.996" y="1.012" width="3.992" height="11.975"></rect>
					</svg>
					<span>Paused</span>
				</div>
			</div>

			<AgGridReact
				className="ag-theme-quartz ag-theme-quartz-dark"
				rowData={DATA}
				columnDefs={COLUMN}
				rowHeight={43}
				domLayout="autoHeight"
				components={components}
			/>
		</div>
	);
};
export default ActivityPanel;
