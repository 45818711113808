import './index.scss';
const PoolInfo = () => {
	return (
		<div className="pool-info">
			<div className="pool-info-header">
				<span>Pool info</span>
				<a
					target="_blank"
					rel="noreferrer"
					href="https://solscan.io/account/2rcBVbL2snTNDSeb4Qv9ZwU5uSi6EVcp8T2kAtucKh8j"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16px"
						height="16px"
						fill="#9A9FAA"
						viewBox="0 0 20 20"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM6.465 5.501a.386.386 0 00-.266.11L4.39 7.42a.188.188 0 00.133.32h9.164c.101 0 .197-.04.266-.109l1.81-1.81a.188.188 0 00-.133-.32H6.465zm0 6.758a.376.376 0 00-.266.11l-1.81 1.81a.188.188 0 00.133.32h9.164c.101 0 .197-.04.266-.11l1.81-1.81a.188.188 0 00-.133-.32H6.465zm7.487-3.289a.376.376 0 00-.266-.11H4.522a.188.188 0 00-.133.321l1.81 1.81c.07.07.165.11.266.11h9.164a.188.188 0 00.133-.32l-1.81-1.81z"
						></path>
					</svg>
				</a>
			</div>

			<div className="item">
				<span>Total liq</span>
				<span>
					$8,338.81(19.45 SOL)
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16px"
						height="16px"
						fill="#88D693"
						viewBox="0 0 12 12"
					>
						<path d="M8.333 4.667h-.38v-.762A1.887 1.887 0 006.047 2a1.887 1.887 0 00-1.904 1.905v.762h-.381A.764.764 0 003 5.43v3.81c0 .418.343.761.762.761h4.571c.42 0 .762-.343.762-.761v-3.81a.764.764 0 00-.762-.762zM6.047 8.096a.765.765 0 01-.761-.762c0-.42.343-.763.761-.763.42 0 .763.344.763.763 0 .419-.343.762-.763.762zM7.23 4.667H4.867v-.762c0-.648.533-1.18 1.18-1.18.649 0 1.182.532 1.182 1.18v.762z"></path>
					</svg>
				</span>
			</div>

			<div className="item">
				<span>Market cap</span>
				<span>$4,764.51</span>
			</div>

			<div className="item">
				<span>Holders</span>
				<span>122</span>
			</div>

			<div className="item">
				<span>Total supply</span>
				<span>999.7M</span>
			</div>

			<div className="item">
				<span>Pair</span>
				<span className='clickable'>
					2rcBV...h8j
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12px"
						height="12px"
						fill="#5C6068"
						viewBox="0 0 12 12"
					>
						<g clipPath="url(#clip0_6972_490)">
							<rect y="2.571" width="9.429" height="9.429" rx="2.571"></rect>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2.713 1.728c.094-.01.19-.014.287-.014h4.286a3 3 0 013 3V9c0 .097-.005.193-.014.287A2.572 2.572 0 0012 6.857V2.571A2.571 2.571 0 009.429 0H5.143c-1.125 0-2.08.722-2.43 1.728z"
							></path>
						</g>
						<defs>
							<clipPath id="clip0_6972_490">
								<rect width="12" height="12"></rect>
							</clipPath>
						</defs>
					</svg>
				</span>
			</div>

			<div className="item">
				<span>Token creator</span>
				<span>
					<a
						href="https://gmgn.ai/sol/address/5HzLRJErYNjMcEpBgp6LbgYqxxP92Lma2rk4QTy3Byow"
						target="_blank"
						rel="noreferrer"
					>
						5HzLR...yow(0SOL)
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="12px"
							height="12px"
							fill="#5C6068"
							viewBox="0 0 12 12"
						>
							<g clipPath="url(#clip0_6972_490)">
								<rect y="2.571" width="9.429" height="9.429" rx="2.571"></rect>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.713 1.728c.094-.01.19-.014.287-.014h4.286a3 3 0 013 3V9c0 .097-.005.193-.014.287A2.572 2.572 0 0012 6.857V2.571A2.571 2.571 0 009.429 0H5.143c-1.125 0-2.08.722-2.43 1.728z"
								></path>
							</g>
							<defs>
								<clipPath id="clip0_6972_490">
									<rect width="12" height="12"></rect>
								</clipPath>
							</defs>
						</svg>
					</a>
				</span>
			</div>
			
			<div className="item">
				<span>Pool created</span>
				<span>12/16/2024 15:17</span>
			</div>
		</div>
	);
};
export default PoolInfo;
