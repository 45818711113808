import { GET_USER_INFO_SUCCESS } from '../actions/user';

export const initialState: any = {
	userInfo: null
};

const userReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case GET_USER_INFO_SUCCESS:
			return {
				...state,
				userInfo: action.payload
			};

		default:
			return {
				...state
			};
	}
};
export default userReducer;
