import { IChainConfig, INewCoin } from '../models';
import { ENV_CONFIGS } from '../config';

export const STORAGE_KEY = {
	ACCESS_TOKEN: 'ACCESS_TOKEN',
	EXPIRED_TIME: 'EXPIRED_TIME',
	SIGNATURE: 'SIGNATURE',
	REFERRAL_ID: 'REFERRAL_ID'
};

export const APP_CONFIGS = {
	CHAIN_DEFAULT: 'eth',
	CHAIN_QUERY_PARAM: 'chain',
	LOGO: '/logo.png'
};

export const PAGE_URLS = {
	HOMEPAGE: `/?${APP_CONFIGS.CHAIN_QUERY_PARAM}=${APP_CONFIGS.CHAIN_DEFAULT}`,
	TRENDING: '/',
	MEME: '/meme',
	SUN_PUMP: '/sun-pump',
	NEW_PAIR: '/new-pair',
	DASHBOARD: '/',
	TRADE: '/trade',
	HOLDING: '/holding',
	DISCOVER: '/discover',
	FOLLOW: '/follow',
	MY_WALLET: '/my-wallet',
	REFERRAL: '/referral',
	TOKEN: '/token',
	TG_ALERT_TUTORIAL: 'http://google.com',
	ADD_TWITTER: 'http://google.com',
	JOIN_ADDRESS: '/join/address'
};
export const MENUS = {
	MEME: { text: 'Meme', link: PAGE_URLS.MEME },
	SUN_PUMP: { text: 'SunPump', link: PAGE_URLS.SUN_PUMP },
	// NEW_PAIR: { text: 'Dashboard', link: PAGE_URLS.NEW_PAIR },
	DASHBOARD: { text: 'Dashboard', link: PAGE_URLS.DASHBOARD },
	TRENDING: { text: 'Trending', link: PAGE_URLS.TRENDING },
	TRADE: { text: 'Copy Trade', link: PAGE_URLS.TRADE },
	DISCOVER: { text: 'Discover', link: PAGE_URLS.DISCOVER },
	HOLDING: { text: 'Holding', link: PAGE_URLS.HOLDING },
	FOLLOW: { text: 'Follow', link: PAGE_URLS.FOLLOW }
};

export const CHAINS: IChainConfig[] = [
	{
		id: 'sol',
		icon: '/assets/images/icons/solana.webp',
		text: 'SOL',
		link: '/?chain=sol',
		menus: [
			MENUS.MEME,
			// MENUS.NEW_PAIR,
			MENUS.DASHBOARD,
			// MENUS.TRENDING,
			MENUS.TRADE,
			MENUS.HOLDING,
			MENUS.FOLLOW
		]
	},
	{
		id: 'tron',
		icon: '/assets/images/icons/tron.webp',
		text: 'Tron',
		link: '/?chain=tron',
		menus: [
			MENUS.SUN_PUMP,
			// MENUS.NEW_PAIR,
			MENUS.DASHBOARD,
			// MENUS.TRENDING,
			MENUS.DISCOVER,
			MENUS.HOLDING,
			MENUS.FOLLOW
		]
	},
	{
		id: 'base',
		icon: '/assets/images/icons/base.webp',
		text: 'Base',
		link: '/?chain=base',
		menus: [
			MENUS.DASHBOARD,
			// MENUS.TRENDING,
			MENUS.DISCOVER,
			MENUS.HOLDING,
			MENUS.FOLLOW
		]
	},
	{
		id: 'eth',
		icon: '/assets/images/icons/ether.webp',
		text: 'ETH',
		link: '/?chain=eth',
		menus: [
			MENUS.DASHBOARD,
			// MENUS.TRENDING,
			MENUS.DISCOVER,
			MENUS.HOLDING,
			MENUS.FOLLOW
		]
	},
	{
		id: 'blast',
		icon: '/assets/images/icons/blast.webp',
		text: 'Blast',
		link: '/?chain=blast',
		menus: [MENUS.DASHBOARD, MENUS.HOLDING]
	}
];
export const DEFAULT_COIN: INewCoin = {
	id: 0,
	name: '',
	ticker: '',
	description: '',
	imageOrVideo: '',
	twitterLink: '',
	telegramLink: '',
	websiteLink: ''
};

export const ENDPOINTS = {
	LIST_NFT_MARKET: 'nfts/market',
	NFT_DETAIL: 'nfts/detail',
	NFT_OWNER: 'nfts/owner',
	NFT_COLLECTION: 'nfts/collection',
	LOGIN: 'auth/login',
	USER_INFO: 'user/me',
	USER_REF: 'user/ref',
	USER_REF_COUNT: 'user/ref-count',
	USER_REF_LIST: 'user/ref-list',
	CREATE_TOKEN: 'tokens',
	USER_LOGOUT: 'user/logout',
	// meme token
	FILE_UPLOAD: 'file/upload',
	USER_MY_TOKEN: 'tokens/my-tokens',
	TOKEN_HOT: 'tokens/hot',
	TOKEN_NEW: 'tokens/new',
	TOKEN_TOP: 'tokens/top',
	TOKEN_PRICE: 'token-price/ohlc',
	TOKEN_CONTRACT: 'tokens/contract'
};

export const DEPOSIT_PACKAGES: any = [
	{
		id: 101090040,
		reward: 100,
		price: 1,
		image: '/assets/images/packages/1.png'
	},
	{
		id: 101090050,
		reward: 200,
		price: 2,
		image: '/assets/images/packages/2.png'
	},
	{
		id: 101090060,
		reward: 500,
		price: 5,
		image: '/assets/images/packages/3.png'
	},
	{
		id: 101090070,
		reward: 650,
		price: 6.5,
		image: '/assets/images/packages/4.png'
	},
	{
		id: 101090090,
		reward: 2000,
		price: 20,
		image: '/assets/images/packages/5.png'
	},
	{
		id: 101090100,
		reward: 10000,
		price: 100,
		image: '/assets/images/packages/6.png'
	}
];

export const ACTION_STATUS = {
	APPROVING: 'APPROVING',
	APPROVED: 'APPROVED',
	APPROVE_FAILS: 'APPROVE_FAILS',

	DEPOSIT_PACKAGE_SUBMITTING: 'DEPOSIT_PACKAGE_SUBMITTING',
	DEPOSIT_PACKAGE_SUCCESS: 'DEPOSIT_PACKAGE_SUCCESS',
	DEPOSIT_PACKAGE_FAIL: 'DEPOSIT_PACKAGE_FAIL',

	ACTIVATE_NFT_SUBMITTING: 'ACTIVATE_NFT_SUBMITTING',
	ACTIVATE_NFT_SUCCESS: 'ACTIVATE_NFT_SUCCESS',
	ACTIVATE_NFT_FAIL: 'ACTIVATE_NFT_FAIL',

	BUY_NFT_SUBMITTING: 'BUY_NFT_SUBMITTING',
	BUY_NFT_SUCCESS: 'BUY_NFT_SUCCESS',
	BUY_NFT_FAIL: 'BUY_NFT_FAIL',

	LISTING_NFT_SUBMITTING: 'LISTING_NFT_SUBMITTING',
	LISTING_NFT_SUCCESS: 'LISTING_NFT_SUCCESS',
	LISTING_NFT_FAIL: 'LISTING_NFT_FAIL',

	UNLISTING_NFT_SUBMITTING: 'UNLISTING_NFT_SUBMITTING',
	UNLISTING_NFT_SUCCESS: 'UNLISTING_NFT_SUCCESS',
	UNLISTING_NFT_FAIL: 'UNLISTING_NFT_FAIL',

	DEACTIVATE_NFT_SUBMITTING: 'DEACTIVATE_NFT_SUBMITTING',
	DEACTIVATE_NFT_SUCCESS: 'DEACTIVATE_NFT_SUCCESS',
	DEACTIVATE_NFT_FAIL: 'DEACTIVATE_NFT_FAIL',

	CREATE_MEME_TOKEN_SUBMITTING: 'CREATE_MEME_TOKEN_SUBMITTING',
	CREATE_MEME_TOKEN_SUCCESS: 'CREATE_MEME_TOKEN_SUCCESS',
	CREATE_MEME_TOKEN_FAIL: 'CREATE_MEME_TOKEN_FAIL',

	BUY_EXACT_IN_SUBMITTING: 'BUY_EXACT_IN_SUBMITTING',
	BUY_EXACT_IN_SUCCESS: 'BUY_EXACT_IN_SUCCESS',
	BUY_EXACT_IN_FAIL: 'BUY_EXACT_IN_FAIL',

	BUY_EXACT_OUT_SUBMITTING: 'BUY_EXACT_OUT_SUBMITTING',
	BUY_EXACT_OUT_SUCCESS: 'BUY_EXACT_OUT_SUCCESS',
	BUY_EXACT_OUT_FAIL: 'BUY_EXACT_OUT_FAIL',

	SELL_EXACT_IN_SUBMITTING: 'SELL_EXACT_IN_SUBMITTING',
	SELL_EXACT_IN_SUCCESS: 'SELL_EXACT_IN_SUCCESS',
	SELL_EXACT_IN_FAIL: 'SELL_EXACT_IN_FAIL',

	SELL_EXACT_OUT_SUBMITTING: 'SELL_EXACT_OUT_SUBMITTING',
	SELL_EXACT_OUT_SUCCESS: 'SELL_EXACT_OUT_SUCCESS',
	SELL_EXACT_OUT_FAIL: 'SELL_EXACT_OUT_FAIL'
};

export const MAX_UINT =
	'115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const SUPPORT_SPINE_HERO = [
	'1002',
	'1009',
	'1011',
	'1014',
	'1015',
	'1017'
];
export const ITEMS_PER_PAGE = 12;
export const APP_ROUTES = {
	MARKET: '/marketplace',
	ACCOUNT: '/profile/account'
};

export const CURRENCY_NAME_MAPPING = {
	[ENV_CONFIGS.USDC_ADDRESS]: 'USDC',
	[ENV_CONFIGS.EKL_ADDRESS]: 'EKL'
};

export const NFT_STATUS = {
	AVAILABLE: 'AVAILABLE',
	LISTING_MARKET: 'LISTING_MARKET',
	ACTIVE_IN_GAME: 'ACTIVE_IN_GAME'
};

export const HERO_COLLECTIONS: any = {
	'1010': {
		name: 'Cleopatra',
		skills:
			"Pharaoh's Authority, Viper's Kiss, Exquisite Beauty, Queens of king, Avatar of Isis",
		description:
			'Cleopatra is commonly known as the Queen of Egypt. Born in 69 BC, she was one of the monarchs of the Ptolemaic dynasty after the conquest of Egypt by Alexander the Great, and the last female pharaoh of the dynasty. She was unusually talented, beautiful, clever, and witty, and a skilled tactician. She harbored many ambitions and had quite a dramatic life. In particular, she was involved in the political maelstrom at the end of the Roman Republic, and was closely involved with Caesar and Mark Antony. The stories and rumors than accompanied her made her a well-known figure in works of literature and art. The Romans hated her because she had almost made Rome a region of Egypt; the Egyptians hailed her as a courageous warrior because she had won 22 years of peace for a weak Egypt. In 30 BC, when Octavian attacked Egypt, Cleopatra committed suicide. Egypt was incorporated into Rome and the civilization of Ancient Egypt came to an end.',
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/cleopatra.png'
	},
	'1007': {
		name: 'Rurik',
		skills:
			'Savagery Active Skill, Ancestral Shelter, Blood of Varyag, New Dynasty, Angry Outburst',
		description:
			'Kingalas is known as one of the oldest Unipets, it is a historical witness of the development of Unipets.',
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/rurik.png'
	},
	'1009': {
		name: 'Mehmed II',
		skills:
			'Orban Cannon, Across the Seas, Fatih, Glorious Yore , Cannon Blast',
		description:
			"Mehmed II was the 7th monarch of the Ottoman Empire, but many historians claim he was the empire’s true founder. He had a famous nickname, Fatih, meaning “conqueror,” because at the age of 21 he was already able to command an army that captured Constantinople and destroyed the Byzantine Empire, fulfilling the long-standing desire of generations of sultans. Later, he invaded the Balkan hinterlands of the west and fought against the Ak Koyunlu dynasty in the east, laying a solid foundation for the Ottoman Empire's centuries of rule. In addition to being a great military leader, Mehmed II was also an outstanding ruler. He implemented a series of measures that effectively strengthened the rule of the Ottoman Empire, especially his policies of religious and cultural tolerance, which were rare in those times. These measures led to the stabilization of the Ottoman Empire, and to significant economic and cultural development.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/mehmed_II.png'
	},
	'1006': {
		name: 'Oman I',
		skills:
			"Annexation , Ottoman's Onset, Ottoman's Dream, Ottoman's Sword, Ottoman's Light",
		description:
			"IOsman I founded the Ottoman Empire. He was the son of Ertuğrul and was born in Söğüt, in northern Asia Minor. He was a devout believer, abiding by his religious practices and living a frugal life. Osman was a brilliant military strategist and organizer. Under his leadership, the Ottoman Empire grew almost non-stop. Osman captured many Byzantine cities, earning the title of “Bey” (“Chief”). After capturing the city that he renamed Kara Hissar, he moved his capital there as a stronghold for further advancement. Then, Osman I set his sights on Bursa, the last stronghold of the Byzantine Empire in Asia Minor. Once Bursa had been taken, the Sea of Marmara, which led to Europe, lay wide open. The siege was hard fought, going back and forth for 9 years. Right when he was on the verge of breaking into the city, Osman's life came to an end. He gave his name to the Ottoman Empire, which lasted over 600 years. This vast empire spanned the three continents of Asia, Africa, and Europe, and was one of the most powerful and prosperous empires in the world.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/oman_i.png'
	},
	'1017': {
		name: 'AI-Mansur Qalawun',
		skills:
			"Sultan's Rage , Victorious Courage , Victory of Homs , Mamluk's Sword, Bane of Mongolians",
		description:
			"Al-Mansur Qalawun (1222 - 1290) was the seventh Bahri Mamluk sultan to rule Egypt. He was sold as a slave for a thousand dinars when he was 30 years old. He barely spoke Arabic, but soon joined the Mamluk and befriended Baibars. Qalawun and Baibars convicted Mamluk's second sultan, who fled to Damascus. Qalawun and Baibars established a deep friendship during this period. Qalawun followed Baibars and together founded the new Mamluk dynasty.After Baibar's death, the dynasty was engulfed in turmoil until Baibar's seven-year-old son finally took the throne. Frequent scuffles and young leaders caused further unrest in the country. Qalawun realized what needed to be done. He exiled Baibar's young son and ascended the throne as the new Sultan. During his reign, he stopped the Mongolians from setting foot in Egypt and defeated the Crusaders. The dynasty stabilized and Mamluk reputation spread far and wide. He lived a long life, dying at the age of 70 due to an illness. His life's merits were worthy of the people under his rule and his two sons succeeded the throne. The dynasty entered a prosperous golden age under Qalawun and his sons' rule.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/ai_mansur_qalawun.png'
	},
	'1012': {
		name: 'Harun al-Rashid',
		skills:
			"Arabian Nights, Golden Age, Worldwide Fame, Victorious One, Baghdad's Radiance",
		description:
			"Harun al-Rashid is the most famous Caliph of the Abbasid dynasty in the Arab Empire. He became famous in the West for his Alliance with Charlemagne, King of the Franks, as well as for his many anecdotes, vividly illustrated in the world-famous book One Thousand and One Nights. He was well educated by the court in matters of religion and culture, and was well versed in the teachings and laws of Islam. With his deep love for philosophy, poetry, and music, he was a master of both sword and pen. He went on two campaigns at the ages of 15 and 17, defeating Byzantium and commanding a yearly tribute. This earned him a widespread reputation, and his father awarded him the title of Rashid, meaning “Orthodox” or “Rightly-Guided”. In 786, he succeeded the Caliph due to the sudden death of his brother. During the 23 years of his reign, the country was strong, economically prosperous, and culturally advanced. The capital, Baghdad, became the political, economic, and cultural center of the Arab Empire, and a gathering-place for scholars. During his reign, he was very supportive of academia and generous toward his subjects. He was also very farsighted in diplomacy and world politics, which resulted in the Abbasid dynasty’s victory over Byzantium and the alliance with Charlemagne. The Arabs’ ability to absorb the best of Greek, Persian, Indian, and other foreign cultures in a short period of time to create an encompassing yet distinctive Arab culture was clearly related to Harun's enthusiastic support and active encouragement of literature and the arts. Harun’s rule heralded a golden age for the Abbasid dynasty. But his mistakes in handling internal affairs laid the roots of future division. He divided the country evenly between his two sons. After his death, civil war broke out immediately.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/harun_al_rashid.png'
	},
	'1002': {
		name: 'Ivan IV',
		skills:
			'Thunder Monarch, Battle of Molodi, Heart of Stone, Battle-Hardened, Terrible Ivan',
		description:
			'Ivan IV Vasilyevich, aka Ivan Grozny or “Ivan the Terrible”, was the first Tsar of the Russian Rurik dynasty and the Grand Duke of Moscow. Ivan IV was an intelligent, wise, and capable man who was very good at expanding his empire. He annexed the Kazan, Astrakhan, and Siberian khanates, defeated the Crimean khanate, and pushed Russian territory further east. The Mongols were no longer a threat after these efforts. In some campaigns, he went into battle and brought northern Caucasia back under Russian control. During his reign, "Tsar" became the official title of the Russian monarch. He was the best orator in Moscow, with an excellent command of the Russian language, and was one of Russia’s most distinguished linguists and writers. Although most of his work has been lost, it’s clear from his surviving work that he had extraordinary wordplay skills and a biting wit. As a result of his early accession, his mother temporarily acted as regent, and various groups began to vie for power. The constant conflict and assassinations had a profound impact on him as he grew up. He was strong-willed and ruthless from a young age, and harshly repressed the nobility. At the age of 13, he ordered the execution of a rival lord, and he once beat his eldest son to death with a cane in a fit of rage. He was born during a thunderstorm, and some think he got the title Ivan Grozny, “Ivan the Terrible” or “Ivan the Thunderous” because of this. However, the epithet is more likely due to his chilling actions.',
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/ivan4.png'
	},
	'2003': {
		name: "Abul' Abbas",
		skills: 'Annihilation, Bloody Banquet, For Glory, Benevolence,  Butcher',
		description:
			"Abu al-Abbas as-Saffah was the founder of the Abbasid dynasty, and the first Caliph of the Abbasids (750-754). In June of 747, as the declining Umayyad dynasty came to and end, he raised the banner of rebellion against the dynasty. Many dissatisfied groups supported him, including the Iranians and the people of Khurasan. In early 750, the Abbasids met Marwan II's army on the Zab River, a tributary of the Tigris. The Abbasid army was victorious, and the battle hastened the demise of the Umayyad Dynasty, which was complete after Damascus surrendered in April 750. With Kufa as his capital and Iraq as the center of his empire, As-Saffah became Caliph and established the Abbasid dynasty. After taking power, As-Saffah executed nearly every member of the Umayyad family. In his inaugural speech in Kufa, he called himself “Saffah”, which means either “shedder of blood” or “generous, merciful person”.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/abul_abbas.png'
	},
	'1008': {
		name: 'AL-Hajjaj ibn Yussuf',
		skills:
			'Iron Fist, Siege Tactic, Healing Siege, Eastward Ambitions, Mesopotamian Ruler',
		description:
			'Al-Hajjaj ibn Yusuf was born in 661 to the Banu Thaqif tribe in Ta’if. He was physically ugly, short in stature, and one-eyed, but he was intelligent and eloquent. He was despised by his classmates because of his disability, and developed a cruel and withdrawn personality. As an adult, his talents remained unrecognized. Aggrieved by this, he became increasingly ruthless and violent, often flogging soldiers and causing discontent among his subordinates. Hearing this, the Caliph Abd al-Malik summoned him and, appreciating his abilities, made him an officer in the campaign against Basra. Later, his true talents were revealed. In 691, Al-Hajjaj ibn Yusuf took over as commander of the Mecca expedition, first going to his hometown of Ta’if, then to Yathrib (modern-day Medina) to summon the scattered soldiers and convert them into his own army. Once the fighting began, he laid siege to Mecca for six months and successfully conquered the city. He was rewarded by Abd al-Malik with the position of governor of the Arabian Peninsula and Iraq. During his tenure, his administration was brutal and bloody, and he was known as the Iron Fist because of his repressive policies and subjugation of the Mawali (new converts to Islam).',
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/al_hajjaj_ibn_yussuf.png'
	},
	'1003': {
		name: 'Ramesses II',
		skills:
			"Pharaoh's Might ,Warring Ruler, Great Achievements, King of Kings, Sun's Favor",
		description:
			"Ramesses II, son of Pharaoh Seti I, was Pharaoh during the 19th dynasty of ancient Egypt. An outstanding politician, strategist, writer, and poet, his rule was the last golden era of the new kingdom of Egypt. During his reign, Ramesses II carried out a series of expeditions and clashed with the Hittite Empire, a powerful force at the time. After the death of the Hittite king, the new king entered into a contract with Egypt to form a military alliance. This is arguably the first famous international alliance in history. Out of concern for the Hittites’ military power, Ramesses II ordered a new city to be built on the northeastern Nile Delta as his capital and named it Per-Ramesses (meaning “Home of Ramesses”). Ramesses II created many works for his famous queen and ordered sculptures to be built in her honor. Ramesses II was one of Egypt's most famous pharaohs, and his enthusiasm for huge civil works left his mark all over Egypt. The reign of Ramesses II gave Egypt a radiance never seen before. He passed away at the age of 96, taking with him the last afterglow of his shining light.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/ramesses_ii.png'
	},
	'1005': {
		name: 'Alp Arslan',
		skills: 'Stately Aura, Brave Lion, Proud Archer, Determination, Rage',
		description:
			'Alp Arslan, whose real name was Muhammad bin Dawud Chaghri, was the second Sultan of the Seljuk Empire. During his reign, Alp Arslan won many battles against Egypt’s Fatimid dynasty and the Eastern Roman Empire, which enabled him to expand his empire’s territory. Later generations admired his uncommon military prowess and honored him with the Turkish title "Alp Arslan", meaning “Heroic Lion”. His political activities were founded on the great ideals of the three monarchs of Seljuk, and he made several attacks on Byzantium, Georgia, and Armenia. In 1064, he led an attack on the Byzantine Empire, defeating and capturing the Byzantine Emperor Romanus IV thanks to his excellent strategy. This was the decisive battle of the Byzantine–Seljuk wars, in which Alp Arslan truly showed his talents. But his aim was not to immediately annihilate the Byzantine Empire, just to revise its borders. He generously released the captured emperor so that he could demand more tributes. On November 25, 1072, at the age of 43, Alp Arslan was unexpectedly stabbed to death by a prisoner during a hunting trip. Conquest was his greatest pleasure in life. He had no interest in the practicalities of governance, entrusting them to his ministers along with the administration of the empire.',
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/alp_arslan.png'
	},
	'1013': {
		name: 'Saladin',
		skills:
			'The Hero, Ghilman Cavalry, Economic Diplomacy, Reinforcement Slayer, Invincibility',
		description:
			"Saladin al-Hajjaj ibn Yusuf, known as Saladin, was the first Sultan of the Ayyubid dynasty in Egypt. He is known as a national hero for his outstanding performance in many battles. Saladin's most outstanding achievement was the capture of Jerusalem, which brought a dramatic turnaround in the struggle for the land. He also consolidated Syria, Egypt, and vast areas of West Asia under the rule of a unified regime. He was widely praised for his generosity, so he left behind only 1 dinar and 47 dirhams when he died. On his deathbed, Saladin exhorted his eldest son, Malik al-Afdal, to be sensitive to the situation of the people and care for their plight. On March 3rd, 1193, Saladin died in Damascus at the age of 55. After his death, people often visited his mausoleum to pay their respects.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/saladin.png'
	},
	'1011': {
		name: 'Hatshepsut',
		skills:
			"Female Pharaoh, Trade Routes, Queen's Informant, Blood of Ahmose, Amun's Blessingr",
		description:
			"Hatshepsut reigned from 1479–1458 BC, and was Queen of the 18th dynasty of Ancient Egypt. She was the only child of Thutmose I and his queen, and became Pharaoh, founding a prosperous era for Ancient Egypt. She grew up smart, bold, strong, and skilled in the art of politics. Priding herself on being the only legitimate heir to the pharaoh, she dreamed of one day ruling a strong Egypt. She married her half-brother Thutmose II, whose mother was a royal concubine. Thutmose II suffered from fragile health and had no desire to rule. Soon after he succeeded to the throne, the power fell to Hatshepsut. A few years later, Thutmose II fell ill and died. Hatshepsut arranged for Thutmose III to marry her daughter and ascend to the throne. In her capacity as regent, she retained full authority over the affairs of state. Her administration put an end to Egypt's wars abroad, thus destabilizing Egyptian rule in Syria and Palestine. However, her domination over Egypt's trade and commerce with its neighbors also made Egypt prosperous. When Thutmose III grew up, Hatshepsut exiled him to a remote place. Claiming to be the daughter of the sun god Amun, she ascended to the throne. A significant change occurred in the 22nd year of Hatshepsut's reign. Thutmose III suddenly returned from exile and Hatshepsut disappeared. Evidence seems to suggest that this was connected to the appointment of Thutmose III as head of the army. During the independent reign of Thutmose III, words and images referring to Queen Hatshepsut were chiseled off most stone monuments. Hatshepsut I has since become one of the most mysterious Egyptian pharaohs. She was also the first historically confirmed female ruler in the world.",
		image:
			'https://kingdom-nft-2024.s3.ap-southeast-1.amazonaws.com/images/hatshepsut.png'
	},
	'1014': {
		name: 'Tran Hung Dao',
		skills:
			"Female Pharaoh, Trade Routes, Queen's Informant, Blood of Ahmose, Amun's Blessingr",
		description:
			"Hatshepsut reigned from 1479–1458 BC, and was Queen of the 18th dynasty of Ancient Egypt. She was the only child of Thutmose I and his queen, and became Pharaoh, founding a prosperous era for Ancient Egypt. She grew up smart, bold, strong, and skilled in the art of politics. Priding herself on being the only legitimate heir to the pharaoh, she dreamed of one day ruling a strong Egypt. She married her half-brother Thutmose II, whose mother was a royal concubine. Thutmose II suffered from fragile health and had no desire to rule. Soon after he succeeded to the throne, the power fell to Hatshepsut. A few years later, Thutmose II fell ill and died. Hatshepsut arranged for Thutmose III to marry her daughter and ascend to the throne. In her capacity as regent, she retained full authority over the affairs of state. Her administration put an end to Egypt's wars abroad, thus destabilizing Egyptian rule in Syria and Palestine. However, her domination over Egypt's trade and commerce with its neighbors also made Egypt prosperous. When Thutmose III grew up, Hatshepsut exiled him to a remote place. Claiming to be the daughter of the sun god Amun, she ascended to the throne. A significant change occurred in the 22nd year of Hatshepsut's reign. Thutmose III suddenly returned from exile and Hatshepsut disappeared. Evidence seems to suggest that this was connected to the appointment of Thutmose III as head of the army. During the independent reign of Thutmose III, words and images referring to Queen Hatshepsut were chiseled off most stone monuments. Hatshepsut I has since become one of the most mysterious Egyptian pharaohs. She was also the first historically confirmed female ruler in the world.",
		image: '/general/hero1014.png'
	},
	'1015': {
		name: 'Evony',
		skills:
			"Female Pharaoh, Trade Routes, Queen's Informant, Blood of Ahmose, Amun's Blessingr",
		description:
			"Hatshepsut reigned from 1479–1458 BC, and was Queen of the 18th dynasty of Ancient Egypt. She was the only child of Thutmose I and his queen, and became Pharaoh, founding a prosperous era for Ancient Egypt. She grew up smart, bold, strong, and skilled in the art of politics. Priding herself on being the only legitimate heir to the pharaoh, she dreamed of one day ruling a strong Egypt. She married her half-brother Thutmose II, whose mother was a royal concubine. Thutmose II suffered from fragile health and had no desire to rule. Soon after he succeeded to the throne, the power fell to Hatshepsut. A few years later, Thutmose II fell ill and died. Hatshepsut arranged for Thutmose III to marry her daughter and ascend to the throne. In her capacity as regent, she retained full authority over the affairs of state. Her administration put an end to Egypt's wars abroad, thus destabilizing Egyptian rule in Syria and Palestine. However, her domination over Egypt's trade and commerce with its neighbors also made Egypt prosperous. When Thutmose III grew up, Hatshepsut exiled him to a remote place. Claiming to be the daughter of the sun god Amun, she ascended to the throne. A significant change occurred in the 22nd year of Hatshepsut's reign. Thutmose III suddenly returned from exile and Hatshepsut disappeared. Evidence seems to suggest that this was connected to the appointment of Thutmose III as head of the army. During the independent reign of Thutmose III, words and images referring to Queen Hatshepsut were chiseled off most stone monuments. Hatshepsut I has since become one of the most mysterious Egyptian pharaohs. She was also the first historically confirmed female ruler in the world.",
		image: '/general/hero1015.png'
	},
	'149': {
		name: 'Hero1',
		skills:
			"Female Pharaoh, Trade Routes, Queen's Informant, Blood of Ahmose, Amun's Blessingr",
		description:
			"Hatshepsut reigned from 1479–1458 BC, and was Queen of the 18th dynasty of Ancient Egypt. She was the only child of Thutmose I and his queen, and became Pharaoh, founding a prosperous era for Ancient Egypt. She grew up smart, bold, strong, and skilled in the art of politics. Priding herself on being the only legitimate heir to the pharaoh, she dreamed of one day ruling a strong Egypt. She married her half-brother Thutmose II, whose mother was a royal concubine. Thutmose II suffered from fragile health and had no desire to rule. Soon after he succeeded to the throne, the power fell to Hatshepsut. A few years later, Thutmose II fell ill and died. Hatshepsut arranged for Thutmose III to marry her daughter and ascend to the throne. In her capacity as regent, she retained full authority over the affairs of state. Her administration put an end to Egypt's wars abroad, thus destabilizing Egyptian rule in Syria and Palestine. However, her domination over Egypt's trade and commerce with its neighbors also made Egypt prosperous. When Thutmose III grew up, Hatshepsut exiled him to a remote place. Claiming to be the daughter of the sun god Amun, she ascended to the throne. A significant change occurred in the 22nd year of Hatshepsut's reign. Thutmose III suddenly returned from exile and Hatshepsut disappeared. Evidence seems to suggest that this was connected to the appointment of Thutmose III as head of the army. During the independent reign of Thutmose III, words and images referring to Queen Hatshepsut were chiseled off most stone monuments. Hatshepsut I has since become one of the most mysterious Egyptian pharaohs. She was also the first historically confirmed female ruler in the world.",
		image: '/general/Hero_2001.skel-idle_0.png'
	},
	'148': {
		name: 'Hero2',
		skills:
			"Female Pharaoh, Trade Routes, Queen's Informant, Blood of Ahmose, Amun's Blessingr",
		description:
			"Hatshepsut reigned from 1479–1458 BC, and was Queen of the 18th dynasty of Ancient Egypt. She was the only child of Thutmose I and his queen, and became Pharaoh, founding a prosperous era for Ancient Egypt. She grew up smart, bold, strong, and skilled in the art of politics. Priding herself on being the only legitimate heir to the pharaoh, she dreamed of one day ruling a strong Egypt. She married her half-brother Thutmose II, whose mother was a royal concubine. Thutmose II suffered from fragile health and had no desire to rule. Soon after he succeeded to the throne, the power fell to Hatshepsut. A few years later, Thutmose II fell ill and died. Hatshepsut arranged for Thutmose III to marry her daughter and ascend to the throne. In her capacity as regent, she retained full authority over the affairs of state. Her administration put an end to Egypt's wars abroad, thus destabilizing Egyptian rule in Syria and Palestine. However, her domination over Egypt's trade and commerce with its neighbors also made Egypt prosperous. When Thutmose III grew up, Hatshepsut exiled him to a remote place. Claiming to be the daughter of the sun god Amun, she ascended to the throne. A significant change occurred in the 22nd year of Hatshepsut's reign. Thutmose III suddenly returned from exile and Hatshepsut disappeared. Evidence seems to suggest that this was connected to the appointment of Thutmose III as head of the army. During the independent reign of Thutmose III, words and images referring to Queen Hatshepsut were chiseled off most stone monuments. Hatshepsut I has since become one of the most mysterious Egyptian pharaohs. She was also the first historically confirmed female ruler in the world.",
		image: '/general/Hero_2008.skel-idle_4.png'
	},
	'156': {
		name: 'Hero3',
		skills:
			"Female Pharaoh, Trade Routes, Queen's Informant, Blood of Ahmose, Amun's Blessingr",
		description:
			"Hatshepsut reigned from 1479–1458 BC, and was Queen of the 18th dynasty of Ancient Egypt. She was the only child of Thutmose I and his queen, and became Pharaoh, founding a prosperous era for Ancient Egypt. She grew up smart, bold, strong, and skilled in the art of politics. Priding herself on being the only legitimate heir to the pharaoh, she dreamed of one day ruling a strong Egypt. She married her half-brother Thutmose II, whose mother was a royal concubine. Thutmose II suffered from fragile health and had no desire to rule. Soon after he succeeded to the throne, the power fell to Hatshepsut. A few years later, Thutmose II fell ill and died. Hatshepsut arranged for Thutmose III to marry her daughter and ascend to the throne. In her capacity as regent, she retained full authority over the affairs of state. Her administration put an end to Egypt's wars abroad, thus destabilizing Egyptian rule in Syria and Palestine. However, her domination over Egypt's trade and commerce with its neighbors also made Egypt prosperous. When Thutmose III grew up, Hatshepsut exiled him to a remote place. Claiming to be the daughter of the sun god Amun, she ascended to the throne. A significant change occurred in the 22nd year of Hatshepsut's reign. Thutmose III suddenly returned from exile and Hatshepsut disappeared. Evidence seems to suggest that this was connected to the appointment of Thutmose III as head of the army. During the independent reign of Thutmose III, words and images referring to Queen Hatshepsut were chiseled off most stone monuments. Hatshepsut I has since become one of the most mysterious Egyptian pharaohs. She was also the first historically confirmed female ruler in the world.",
		image: '/general/Hero_3002.skel-idle_0.png'
	}
};
