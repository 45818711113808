import './index.scss';
const ConnectTGBot = () => {
	return (
		<div className="connect-tg-bot">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16px"
				height="16px"
				fill="currentColor"
				viewBox="0 0 20 20"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.276 1.652a2 2 0 00-.765-.152H3.5a3 3 0 00-3 3v11a3 3 0 003 3h13a3 3 0 003-3V7.993a2 2 0 00-2-2h-1.989V3.5a2 2 0 00-1.235-1.848zM2 4.5a1.501 1.501 0 001.356 1.493L3.5 6H14v1.5H3.5A3 3 0 012 7.098V15.5a1.5 1.5 0 001.356 1.493L3.5 17h13a1.5 1.5 0 001.493-1.356L18 15.5V7.993a.5.5 0 00-.41-.492l-.09-.008h-3.489V3.5a.5.5 0 00-.41-.492L13.511 3H3.5a1.5 1.5 0 00-1.493 1.356L2 4.5z"
				></path>
				<path d="M13.25 9.5h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 110-1.5z"></path>
			</svg>
			<span>Trading in seconds 🚀</span>
			<a
				href="https://t.me/"
				target="_blank"
				className="btn btn-warning btn-sm"
				rel="noreferrer"
			>
				Open TGBot
			</a>
		</div>
	);
};
export default ConnectTGBot;
