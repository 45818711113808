import { CustomCellRendererProps } from 'ag-grid-react';
import './cell-action.scss';
const ActivityCellAction = (props: CustomCellRendererProps) => {
	return (
		<div className="activity-cell-action">
			<button type="button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="currentColor"
					viewBox="0 0 20 20"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4 6a2 2 0 012-2h2a1 1 0 000-2H6a4 4 0 00-4 4v8a4 4 0 004 4h8a4 4 0 004-4v-2a1 1 0 10-2 0v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zm7-3a1 1 0 011-1h4a2 2 0 012 2v4a1 1 0 11-2 0V5.414l-5.293 5.293a1 1 0 01-1.414-1.414L14.586 4H12a1 1 0 01-1-1z"
					></path>
				</svg>
				<span>Share</span>
			</button>
			<button type="button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16px"
					height="16px"
					fill="#9AA0AA"
					viewBox="0 0 16 16"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M2.702 3.225l.006.006 3.635 3.547c.355.346.554.82.554 1.315v3.898a.6.6 0 11-1.2 0V8.093a.636.636 0 00-.192-.456L1.87 4.09C1.088 3.327 1.628 2 2.72 2h10.562c1.093 0 1.633 1.328.85 2.09l-3.64 3.547a.636.636 0 00-.191.456v5.634a.6.6 0 01-1.2 0V8.093c0-.495.2-.97.554-1.315l3.64-3.547.005-.006.001-.002-.002-.012a.03.03 0 00-.007-.01h-.002l-.008-.001H2.71a.03.03 0 00-.006.011.03.03 0 00-.003.012l.001.002z"
					></path>
				</svg>
			</button>
			<a
				rel="noreferrer"
				target="_blank"
				href="https://solscan.io/tx/HBjzUX1fnPEsP46Lk7T2vhAwmG9TEDS3mEzFXbGWSiCJsxYRHgtKRUczT544wJwMucphGVFivpFa1CdaUoZTTQS"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16px"
					height="16px"
					fill="currentColor"
					viewBox="0 0 20 20"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM6.465 5.501a.386.386 0 00-.266.11L4.39 7.42a.188.188 0 00.133.32h9.164c.101 0 .197-.04.266-.109l1.81-1.81a.188.188 0 00-.133-.32H6.465zm0 6.758a.376.376 0 00-.266.11l-1.81 1.81a.188.188 0 00.133.32h9.164c.101 0 .197-.04.266-.11l1.81-1.81a.188.188 0 00-.133-.32H6.465zm7.487-3.289a.376.376 0 00-.266-.11H4.522a.188.188 0 00-.133.321l1.81 1.81c.07.07.165.11.266.11h9.164a.188.188 0 00.133-.32l-1.81-1.81z"
					></path>
				</svg>
			</a>
		</div>
	);
};
export default ActivityCellAction;
