import { graphic } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './index.scss';

let firstLoad = true;
const ReferralChartCard = () => {
	const LEVELS = [
		{ vol: 0, earn: 0 },
		{ vol: 50, earn: 100 },
		{ vol: 250, earn: 500 },
		{ vol: 500, earn: 2000 },
		{ vol: 1000, earn: 5000 },
		{ vol: 1500, earn: 5000 }
	];
	const [levelIndex, setLevelIndex] = useState(1);
	const option = {
		notMerge: true,
		animation: firstLoad,
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: ['', 'Vol.50k', 'Vol.250k', 'Vol.500k', 'Vol.1000k', ''],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				color: function (_value: string, index: number) {
					return index === levelIndex ? '#ffc107' : '#fff';
				}
			}
		},
		yAxis: {
			type: 'value',
			show: false,
			min: 0,
			max: 1500
		},
		grid: {
			left: '-2%',
			right: '-2%',
			bottom: '8%',
			top: '0%'
		},
		series: [
			{
				data: [0, 100, 250, 500, 900, 1500],
				color: '#ffc107',
				type: 'line',
				smooth: true,
				symbolSize: 12,
				areaStyle: {
					color: new graphic.LinearGradient(0, 0, 0, 1, [
						{ offset: 0, color: 'rgba(255, 193, 7, 0.4)' },
						{ offset: 0.9, color: 'rgba(255, 193, 7, 0)' }
					])
				}
			}
		]
	};

	useEffect(() => {
		firstLoad = false;
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setLevelIndex(levelIndex === 4 ? 1 : levelIndex + 1);
		}, 3000);

		return () => clearInterval(interval);
	}, [levelIndex]);

	const onChartClick = (params: any) => {
		if (params.componentType === 'series') {
			setLevelIndex(params.dataIndex);
		}
	};
	const onYAxisLabelClick = (params: any) => {
		debugger;
		console.log('Y-axis label clicked:', params);
		alert(`Y-axis label clicked: ${params.value}`);
	};

	const onEvents = {
		click: onChartClick,
		axisLabelClick: onYAxisLabelClick
	};

	return (
		<Card className="mf-referral-chart-card">
			<Card.Body>
				<div className="info">
					<h3>
						Invite Friends and Earn
						<br />
						Commissions Get up to
					</h3>
					<div className="value">
						<span>${LEVELS[levelIndex].earn}</span>
						Rebates
					</div>
				</div>
				<ReactECharts option={option} onEvents={onEvents} />
				<div className={`pop pop-${levelIndex - 1}`}>
					<span>Earn rewards</span>
					<b>
						<span>${LEVELS[levelIndex].earn}</span>/month
					</b>
				</div>
			</Card.Body>
		</Card>
	);
};
export default ReferralChartCard;
