import { CustomCellRendererProps } from 'ag-grid-react';
import './token-price.scss';
import { getFullDisplayBalance } from '../../../utils/number';
const TokenPriceItem = (props: CustomCellRendererProps) => {
	return (
		<div className="trending-token-price">
			<span>{props.data.price}</span>
			<span className={props.data.volume > 0 ? 'green' : 'red'}>
				{props.data.volume > 0 ? '+' : ''}
				{getFullDisplayBalance(props.data.volume)}%
			</span>
		</div>
	);
};
export default TokenPriceItem;
