import { get } from 'lodash';
import { useSelector } from 'react-redux';

export function useEthBalance(): any {
	return useSelector((state: any) => get(state, 'wallet.ethBalance', 0));
}

export function useUsdcBalance(): any {
	return useSelector((state: any) => get(state, 'wallet.usdcBalance', 0));
}
export function useEklBalance(): any {
	return useSelector((state: any) => get(state, 'wallet.eklBalance', 0));
}
export function useAvaxBalance(): any {
	return useSelector((state: any) => get(state, 'wallet.avaxBalance', 0));
}

export function useMyToken(): any {
	return useSelector((state: any) => get(state, 'token.myTokens', []));
}

export function useHotToken(): any {
	return useSelector((state: any) => get(state, 'token.hotTokens', []));
}

export function useNewToken(): any {
	return useSelector((state: any) => get(state, 'token.newTokens', []));
}

export function useTopToken(): any {
	return useSelector((state: any) => get(state, 'token.topTokens', []));
}
