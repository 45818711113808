import { useState } from 'react';
import {
	Accordion,
	Button,
	Col,
	Form,
	InputGroup,
	Modal,
	Row
} from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import axios from 'axios';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../config';
import { ACTION_STATUS, STORAGE_KEY } from '../../constants';
import { UseCreateMemeToken } from '../../hooks/useMemeToken';
import { useActiveWeb3React } from '../../hooks';
import LoadingButton from '../loading-button';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { requestCreateToken } from '../../redux/services/token';
import {
	GET_HOT_LIST_TOKEN_REQUEST,
	GET_NEW_TOKEN_REQUEST,
	GET_TOP_TOKEN_REQUEST,
	GET_USER_LIST_TOKEN_REQUEST
} from '../../redux/actions/user';
import { useDispatch } from 'react-redux';

export type StartNewCoinPopupProps = {
	show: boolean;
	onCreate?: () => void;
	onClose: () => void;
};
const StartNewCoinPopup = ({
	show,
	onCreate,
	onClose
}: StartNewCoinPopupProps) => {
	const dispatch = useDispatch();
	const { account, chainId, provider } = useActiveWeb3React();
	const fileTypes = ['jpg', 'png', 'webp', 'gif', 'mp4', 'webm'];
	const [file, setFile] = useState<File | null>(null);
	const [hash, setHash] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [url, setUrl] = useState<string>('');
	const [formData, setFormData] = useState<any>({
		name: '',
		ticker: '',
		description: '',
		transactionId: '',
		website: '',
		telegram: '',
		twitter: ''
	});

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleFileChange = (file: File) => {
		setFile(file);
		handleUpload(file);
	};

	const handleUpload = async (file: File) => {
		if (!file) {
			toast.warn('Please select a file first!');
			return;
		}

		const formData = new FormData();
		formData.append('file', file); // Attach the file to FormData

		try {
			const token = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
			// Make the API request to upload the file
			const response = await axios.post(
				`${ENV_CONFIGS.BASE_URL}file/upload`,
				formData,
				{
					headers: {
						accept: 'application/json',
						// 'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${token}`
					}
				}
			);
			console.log('responseresponse', response);
			setUrl(get(response, 'data.data.url', ''));
		} catch (error) {
			console.error('Error uploading file:', error);
			toast.error('Failed to upload file. Please try again.');
		}
	};

	const handleClose = () => {
		setFile(null);
		onClose();
	};

	const showSuccessToast = () => {
		toast(
			<div>
				<span>Create token "{formData.name}" successfully!</span>
				<button
					onClick={() => {
						window.open(
							`${ENV_CONFIGS.EXPLORER_URL}${EXPLORER_TYPES.TX}/${hash}`
						);
					}}
					style={{
						marginLeft: '10px',
						padding: '5px 10px',
						border: 'none',
						background: '#007bff',
						color: '#fff',
						borderRadius: '3px',
						cursor: 'pointer'
					}}
				>
					View on Explorer
				</button>
			</div>,
			{
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			}
		);
	};

	const handleCreateToken = async () => {
		try {
			setLoading(true);
			const signature = localStorage.getItem(STORAGE_KEY.SIGNATURE);
			UseCreateMemeToken(
				{
					name: formData.name,
					symbol: formData.ticker,
					nonce: 1,
					signature,
					account,
					web3Provider: provider
				},
				async (result: any) => {
					console.log('useCreateMemeToken===', result);
					if (result.status === ACTION_STATUS.CREATE_MEME_TOKEN_SUCCESS) {
						handleCreateTokenBE(result?.data);
						setLoading(false);
						setHash(result?.data);
						showSuccessToast();
						handleClose();
					}
					if (result.status === ACTION_STATUS.CREATE_MEME_TOKEN_FAIL) {
						setLoading(false);
						toast.error(`Fail to create token "${formData.name}"`);
					}
				}
			);
		} catch (error) {
			console.log('useCreateMemeToken error====', error);
			setLoading(false);
			toast.error(`Fail to create token "${formData.name}"`);
		}
	};
	const handleCreateTokenBE = async (transactionHash: string) => {
		try {
			const res = await requestCreateToken({
				name: formData.name,
				symbol: formData.ticker,
				creator: account,
				transaction_id: transactionHash,
				chain_id: chainId.toString(),
				decimals: 18,
				logo_url: url,
				description: formData.description,
				website: formData.website,
				telegram: formData.telegram,
				twitter: formData.twitter
			});
			console.log('handleCreateTokenBE====', res);
			if (get(res, 'message', '') === 'success') {
				dispatch({ type: GET_USER_LIST_TOKEN_REQUEST });
				dispatch({ type: GET_HOT_LIST_TOKEN_REQUEST });
				dispatch({ type: GET_NEW_TOKEN_REQUEST });
				dispatch({ type: GET_TOP_TOKEN_REQUEST });
			}
		} catch (error) {
			console.log('handleCreateTokenBE error', error);
		}
	};
	const resetData = () => {
		setLoading(false);
		setFormData({
			name: '',
			ticker: '',
			description: '',
			transactionId: '',
			website: '',
			telegram: '',
			twitter: ''
		});
		setUrl('');
	};
	return (
		<Modal
			scrollable
			centered
			show={show}
			onShow={resetData}
			backdrop="static"
			keyboard={false}
			onHide={handleClose}
		>
			<Modal.Header closeButton={!loading}>
				<Modal.Title>Start a new coin</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col sm={8} xs={7}>
						<Form.Group className="mb-3" controlId="txtName">
							<Form.Label>Name</Form.Label>
							<Form.Control
								name="name"
								type="text"
								placeholder=""
								autoFocus
								required
								value={formData.name}
								onChange={handleChange}
							/>
						</Form.Group>
					</Col>
					<Col sm={4} xs={5}>
						<Form.Group className="mb-3" controlId="txtTicker">
							<Form.Label>Symbol</Form.Label>
							<Form.Control
								name="ticker"
								type="text"
								placeholder=""
								maxLength={10}
								required
								value={formData.ticker}
								onChange={handleChange}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group className="mb-3" controlId="txtDescription">
					<Form.Label>Description</Form.Label>
					<Form.Control
						name="description"
						as="textarea"
						rows={2}
						required
						value={formData.description}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="txtImageOfVideo">
					<Form.Label>Image or Video</Form.Label>
					<div className="mm-uploader">
						<FileUploader
							name="imageOrVideo"
							required
							handleChange={handleFileChange}
							types={fileTypes}
						/>
					</div>
					{file && (
						<div className="text-center py-3">
							{file.type.startsWith('image') ? (
								<img
									src={URL.createObjectURL(file)}
									alt="preview"
									height={100}
								/>
							) : file.type.startsWith('video') ? (
								<video height={100} className="w-100" controls>
									<source src={URL.createObjectURL(file)} type={file.type} />
									Your browser does not support the video tag.
								</video>
							) : (
								<p>File type not supported for preview</p>
							)}
						</div>
					)}
				</Form.Group>
				<Form.Group>
					<Accordion defaultActiveKey="-1">
						<Accordion.Item eventKey="0">
							<Accordion.Header>More options</Accordion.Header>
							<Accordion.Body>
								<InputGroup className="mb-2">
									<InputGroup.Text id="lbl">Twitter</InputGroup.Text>
									<Form.Control
										name="twitter"
										type="text"
										placeholder="optional"
										value={formData.twitter}
										onChange={handleChange}
									/>
								</InputGroup>
								<InputGroup className="mb-2">
									<InputGroup.Text>Telegram</InputGroup.Text>
									<Form.Control
										name="telegram"
										type="text"
										placeholder="optional"
										value={formData.telegram}
										onChange={handleChange}
									/>
								</InputGroup>
								<InputGroup>
									<InputGroup.Text>Website</InputGroup.Text>
									<Form.Control
										name="website"
										type="url"
										placeholder="optional"
										value={formData.website}
										onChange={handleChange}
									/>
								</InputGroup>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Form.Text className="opacity-25">
					Tip: Coin data cannot be changed after creation
				</Form.Text>
				<LoadingButton
					title="Create coin"
					isLoading={loading}
					disable={
						formData.name?.length === 0 ||
						formData.ticker?.length === 0 ||
						url?.length === 0
					}
					handlePress={() => handleCreateToken()}
				/>
				<Form.Text>
					When your coin completes its bonding curve you receive 0.5 SOL
				</Form.Text>
			</Modal.Footer>
		</Modal>
	);
};
export default StartNewCoinPopup;
