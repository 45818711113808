import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../config';
import { minimizeAddress } from '../../utils/string';
import { MAP_NETWORK_LOGO } from '../../libs/constants';

interface IMemeSimpCard {
	data?: any;
}

const MemeSimpCard = ({ data }: IMemeSimpCard) => {
	const navigate = useNavigate();
	const handleNavigate = () => {
		navigate(`/token/${data.contract_address}`);
	};
	return (
		<div
			onClick={handleNavigate}
			className="mm-meme-simp-card"
			style={{ cursor: 'pointer' }}
		>
			<Link to="#" className="marked">
				<img src="/assets/images/icons/icon-star.svg" alt="" />
			</Link>
			<Link to="#" className="image">
				<span className="avatar">
					<img src={data?.logo_url} alt="" />
				</span>
				<span className="network">
					<img src={MAP_NETWORK_LOGO[data?.chain_id]} alt="" />
				</span>
			</Link>
			<div className="info">
				<div className="top">
					<Link to="#">
						<b>{data?.name}</b>
					</Link>
					<CopyToClipboard
						text={`${ENV_CONFIGS.EXPLORER_URL}${EXPLORER_TYPES.ADDRESS}/${data?.contract_address}`}
						onCopy={() => {}}
					>
						<button type="button" className="copy">
							<img src="/assets/images/icons/icon-copy-fill.svg" alt="" />
						</button>
					</CopyToClipboard>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>Search on Twitter</Tooltip>}
					>
						<button type="button" className="search">
							<img src="/assets/images/icons/icon-search.svg" alt="" />
						</button>
					</OverlayTrigger>
					<button type="button" className="pump opacity-100">
						<img src="/assets/images/icons/icon-pump.svg" alt="" />
					</button>
				</div>

				<div className="bottom">
					<Link to="#">
						<span>{minimizeAddress(data?.contract_address)}</span>
					</Link>
					{data?.twitter && (
						<a
							href={data?.twitter}
							target="_blank"
							rel="noreferrer"
							aria-label="twitter"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="12"
								fill="currentColor"
								viewBox="0 0 12 12"
							>
								<g clipPath="url(#clip0_7920_513)">
									<path d="M9.282 1h1.71L7.255 5.27l4.394 5.809H8.21L5.515 7.555 2.43 11.08H.721l3.995-4.567L.5 1h3.528l2.436 3.22L9.282 1zm-.6 9.056h.947L3.513 1.97H2.497l6.185 8.086z"></path>
								</g>
								<defs>
									<clipPath id="clip0_7920_513">
										<rect width="12" height="12"></rect>
									</clipPath>
								</defs>
							</svg>
						</a>
					)}
					{data?.website && (
						<a
							href={data?.website}
							target="_blank"
							rel="noreferrer"
							aria-label="website"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="12"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<g clipPath="url(#clip0_1553_2200)">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM6.446 2.831A8.037 8.037 0 003.07 6h2.323c.212-1.023.505-1.96.865-2.77.06-.136.123-.269.188-.399zM2 10c0-.69.088-1.36.252-2h2.842a21.008 21.008 0 000 4H2.252A8.013 8.013 0 012 10zm1.07 4a8.037 8.037 0 003.376 3.169 9.877 9.877 0 01-.188-.399c-.36-.81-.653-1.747-.865-2.77H3.07zm4.372 0c.173.732.392 1.392.643 1.958.328.738.693 1.273 1.047 1.61.35.333.641.432.868.432.227 0 .518-.1.867-.432.355-.337.72-.872 1.048-1.61.251-.566.47-1.226.643-1.958H7.442zm7.165 0a13.716 13.716 0 01-.865 2.77c-.06.136-.123.269-.188.399A8.037 8.037 0 0016.93 14h-2.323zm3.14-2h-2.841a21.027 21.027 0 000-4h2.842c.165.64.252 1.31.252 2s-.087 1.36-.252 2zm-4.851 0H7.104A18.907 18.907 0 017 10c0-.693.037-1.362.104-2h5.792c.067.638.104 1.307.104 2 0 .693-.037 1.362-.104 2zm1.71-6h2.324a8.037 8.037 0 00-3.376-3.169c.065.13.128.263.188.399.36.81.653 1.747.865 2.77zm-6.52-1.958c-.252.566-.47 1.226-.644 1.958h5.116a11.248 11.248 0 00-.643-1.958c-.328-.738-.693-1.273-1.047-1.61C10.518 2.099 10.226 2 10 2c-.227 0-.518.1-.868.432-.354.337-.719.872-1.047 1.61z"
									></path>
								</g>
								<defs>
									<clipPath id="clip0_1553_2200">
										<rect width="20" height="20"></rect>
									</clipPath>
								</defs>
							</svg>
						</a>
					)}
					{data?.telegram && (
						<a
							href={data?.telegram}
							target="_blank"
							rel="noreferrer"
							aria-label="telegram"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="12"
								fill="currentColor"
								viewBox="0 0 12 12"
							>
								<g clipPath="url(#clip0_7920_515)">
									<path d="M11.894 1.91l-1.8 8.487c-.134.6-.49.746-.992.465L6.36 8.842l-1.322 1.273c-.147.147-.27.27-.551.27l.196-2.793L9.764 3c.22-.196-.05-.307-.344-.11L3.138 6.844.43 6c-.588-.183-.6-.588.122-.869l10.582-4.078c.49-.183.918.11.76.857z"></path>
								</g>
								<defs>
									<clipPath id="clip0_7920_515">
										<rect width="12" height="12"></rect>
									</clipPath>
								</defs>
							</svg>
						</a>
					)}
				</div>
			</div>
		</div>
	);
};
export default MemeSimpCard;
