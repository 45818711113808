import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import './index.scss';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../config';
import { useNavigate } from 'react-router-dom';

interface IMemeCard {
	data?: any;
}

const MemeCard = ({ data }: IMemeCard) => {
	const navigate = useNavigate();
	const handleCopyAddress = () => {};
	const handleNavigate = () => {
		navigate(`/token/${data.contract_address}`);
	};
	return (
		<div
			onClick={handleNavigate}
			className="mm-new-creation-card"
			style={{ cursor: 'pointer' }}
		>
			<div className="image">
				<span>
					<img src={data?.logo_url} alt="" />
				</span>
			</div>
			<div className="info">
				<div className="top">
					<b>{data?.name}</b>
					<span>{data?.description}</span>
					<CopyToClipboard
						text={`${ENV_CONFIGS.EXPLORER_URL}${EXPLORER_TYPES.ADDRESS}/${data?.contract_address}`}
						onCopy={handleCopyAddress}
					>
						<button type="button" className="copy">
							<img src="/assets/images/icons/icon-copy-fill.svg" alt="" />
						</button>
					</CopyToClipboard>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>Search on Twitter</Tooltip>}
					>
						<button type="button" className="search">
							<img src="/assets/images/icons/icon-search.svg" alt="" />
						</button>
					</OverlayTrigger>
					<button type="button" className="marked">
						<img src="/assets/images/icons/icon-star.svg" alt="" />
					</button>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>Twitter Rename</Tooltip>}
					>
						<button type="button" className="marked">
							<img
								src="/assets/images/icons/icon-twitter-rename.svg"
								className="opacity-100"
								alt=""
							/>
						</button>
					</OverlayTrigger>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>CTO Community Takeover</Tooltip>}
					>
						<button type="button" className="marked">
							<img
								className="opacity-100"
								src="/assets/images/icons/icon-cto-community-takeover.svg"
								alt=""
							/>
						</button>
					</OverlayTrigger>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>Update DEXScreener Social</Tooltip>}
					>
						<button type="button" className="marked">
							<img
								src="/assets/images/icons/icon-update-dex-screener-social.svg"
								alt=""
							/>
						</button>
					</OverlayTrigger>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>Advertised on DEXScreener</Tooltip>}
					>
						<button type="button" className="marked">
							<img
								src="/assets/images/icons/icon-advertised-on-dex-screener.svg.svg"
								alt=""
							/>
						</button>
					</OverlayTrigger>

					<button type="button" className="buy">
						<img src="/assets/images/icons/icon-buy.svg" alt="" />
						<span>Buy</span>
					</button>
				</div>
				<div className="mid">
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>12/02 06:16:21</Tooltip>}
					>
						<span className="green">38m </span>
					</OverlayTrigger>

					<OverlayTrigger
						placement="bottom"
						overlay={
							<Tooltip>
								Top 10 Hold <span className="red">74.98%</span>
							</Tooltip>
						}
					>
						<div className="mid-item red">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14px"
								height="14px"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M3 14.579a4.117 4.117 0 014.117-4.118c.095 0 .184.048.237.127l2.288 3.432a.57.57 0 00.947 0l2.288-3.432a.284.284 0 01.236-.127 4.117 4.117 0 014.118 4.118v4.048a.373.373 0 01-.374.373H3.373A.373.373 0 013 18.627v-4.048z"></path>
								<circle cx="10.116" cy="4.769" r="4.269"></circle>
							</svg>
							74.98%
						</div>
					</OverlayTrigger>

					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>DEV Holds: 74.9%</Tooltip>}
					>
						<div className="mid-item green">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14px"
								height="14px"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M15 6A5 5 0 115 6a5 5 0 0110 0z"></path>
								<path d="M10 8.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"></path>
								<path d="M19 8.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"></path>
								<path d="M5 8a1 1 0 00-1 1v9a1 1 0 001 1h10a1 1 0 001-1V9a1 1 0 00-1-1H5zm1.7 8h6.6a.7.7 0 110 1.4H6.7a.7.7 0 110-1.4z"></path>
							</svg>
							74.9%
						</div>
					</OverlayTrigger>

					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip>DEV Holds: 74.9%</Tooltip>}
					>
						<div className="mid-item">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14px"
								height="14px"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.5 8a3.5 3.5 0 10-3.463-2.988 2.953 2.953 0 00-.1-.012C7.844 5 6.274 7.972 6 9.457c.379.46 1.49 1.495 2.905 1.947-1.099 1.254-1.56 4.194-1.71 6.275-1.042-.093-2.369-.287-3.614-.653-.677-.2-1.047-.599-1.247-1.116-.211-.546-.242-1.248-.15-2.021.117-1.003.432-1.897.696-2.648.056-.158.11-.31.158-.456.08-.236.149-.44.198-.604.233-.779-.11-1.498-.532-1.914-.408-.404-1.243-.744-1.638-.744v.986c.268.086.65.274.914.537.253.25.356.51.262.823-.038.126-.096.298-.166.506-.073.215-.176.476-.291.768-.326.828-.752 1.905-.867 2.893-.1.843-.148 1.732.148 2.497.307.793 1.1 1.44 2.17 1.884 1.711.595 3.153.595 4.09.595h.011L7.349 19h9.64c.045-.268.026-6.977-1.804-11.014.104.01.209.014.315.014zM11 8a1 1 0 11-2 0 1 1 0 012 0z"
								></path>
								<path d="M11 4c0 .039 0 .078-.002.116-2.138.334-3.601 1.338-4.553 2.45A3 3 0 1111 4z"></path>
							</svg>
							0.04%
						</div>
					</OverlayTrigger>
				</div>

				<div className="bottom">
					<div className="bottom-left">
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip>Status 86.3%</Tooltip>}
						>
							<span className="green">86.3%</span>
						</OverlayTrigger>
						<a
							href="https://www.pump.fun/5yNUmCWZMucbx1ZRoNGhnwexMMLaj7g3GSBW5nD8pump"
							target="_blank"
							rel="noreferrer"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								fill="currentColor"
								viewBox="0 0 14 14"
							>
								<g clipPath="url(#clip0_8853_24)">
									<path d="M4.115 3.76L1.564 6.31a4.067 4.067 0 105.751 5.752L9.867 9.51 4.115 3.76z"></path>
									<path d="M6.882 11.63a3.454 3.454 0 01-4.886-4.885l4.749-4.749a3.454 3.454 0 114.885 4.886L6.882 11.63zm5.181-10.067a4.067 4.067 0 00-5.751 0L1.564 6.311a4.067 4.067 0 105.751 5.752l4.748-4.748a4.067 4.067 0 000-5.752z"></path>
								</g>
								<defs>
									<clipPath id="clip0_8853_24">
										<rect width="14" height="14"></rect>
									</clipPath>
								</defs>
							</svg>
						</a>
						{data?.twitter && (
							<a
								href={data?.twitter}
								target="_blank"
								rel="noreferrer"
								aria-label="twitter"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="12"
									height="12"
									fill="currentColor"
									viewBox="0 0 12 12"
								>
									<g clipPath="url(#clip0_7920_513)">
										<path d="M9.282 1h1.71L7.255 5.27l4.394 5.809H8.21L5.515 7.555 2.43 11.08H.721l3.995-4.567L.5 1h3.528l2.436 3.22L9.282 1zm-.6 9.056h.947L3.513 1.97H2.497l6.185 8.086z"></path>
									</g>
									<defs>
										<clipPath id="clip0_7920_513">
											<rect width="12" height="12"></rect>
										</clipPath>
									</defs>
								</svg>
							</a>
						)}
						{data?.website && (
							<a
								href={data?.website}
								target="_blank"
								rel="noreferrer"
								aria-label="website"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="12"
									height="12"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<g clipPath="url(#clip0_1553_2200)">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM6.446 2.831A8.037 8.037 0 003.07 6h2.323c.212-1.023.505-1.96.865-2.77.06-.136.123-.269.188-.399zM2 10c0-.69.088-1.36.252-2h2.842a21.008 21.008 0 000 4H2.252A8.013 8.013 0 012 10zm1.07 4a8.037 8.037 0 003.376 3.169 9.877 9.877 0 01-.188-.399c-.36-.81-.653-1.747-.865-2.77H3.07zm4.372 0c.173.732.392 1.392.643 1.958.328.738.693 1.273 1.047 1.61.35.333.641.432.868.432.227 0 .518-.1.867-.432.355-.337.72-.872 1.048-1.61.251-.566.47-1.226.643-1.958H7.442zm7.165 0a13.716 13.716 0 01-.865 2.77c-.06.136-.123.269-.188.399A8.037 8.037 0 0016.93 14h-2.323zm3.14-2h-2.841a21.027 21.027 0 000-4h2.842c.165.64.252 1.31.252 2s-.087 1.36-.252 2zm-4.851 0H7.104A18.907 18.907 0 017 10c0-.693.037-1.362.104-2h5.792c.067.638.104 1.307.104 2 0 .693-.037 1.362-.104 2zm1.71-6h2.324a8.037 8.037 0 00-3.376-3.169c.065.13.128.263.188.399.36.81.653 1.747.865 2.77zm-6.52-1.958c-.252.566-.47 1.226-.644 1.958h5.116a11.248 11.248 0 00-.643-1.958c-.328-.738-.693-1.273-1.047-1.61C10.518 2.099 10.226 2 10 2c-.227 0-.518.1-.868.432-.354.337-.719.872-1.047 1.61z"
										></path>
									</g>
									<defs>
										<clipPath id="clip0_1553_2200">
											<rect width="20" height="20"></rect>
										</clipPath>
									</defs>
								</svg>
							</a>
						)}
						{data?.telegram && (
							<a
								href={data?.telegram}
								target="_blank"
								rel="noreferrer"
								aria-label="telegram"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="12"
									height="12"
									fill="currentColor"
									viewBox="0 0 12 12"
								>
									<g clipPath="url(#clip0_7920_515)">
										<path d="M11.894 1.91l-1.8 8.487c-.134.6-.49.746-.992.465L6.36 8.842l-1.322 1.273c-.147.147-.27.27-.551.27l.196-2.793L9.764 3c.22-.196-.05-.307-.344-.11L3.138 6.844.43 6c-.588-.183-.6-.588.122-.869l10.582-4.078c.49-.183.918.11.76.857z"></path>
									</g>
									<defs>
										<clipPath id="clip0_7920_515">
											<rect width="12" height="12"></rect>
										</clipPath>
									</defs>
								</svg>
							</a>
						)}
					</div>
					<div className="bottom-right">
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip>Total Holders</Tooltip>}
						>
							<div className="bottom-right-item">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="12px"
									height="12px"
									fill="#9AA0AA"
									viewBox="0 0 12 12"
								>
									<path d="M4 5a2 2 0 100-4 2 2 0 000 4z"></path>
									<path d="M4 6c-1.657 0-3 .5-3 3a2 2 0 002 2h2a2 2 0 002-2c0-2.5-1.343-3-3-3z"></path>
									<path d="M7.195 10.831c.22.097.418.169.605.169h1.6A1.6 1.6 0 0011 9.4C11 7.5 9.925 7 8.6 7c-.54 0-.947.149-1.194.36.402.535.506 1.144.506 1.864 0 .577-.32 1.272-.717 1.607z"></path>
									<path d="M7 4.6a1.6 1.6 0 103.2 0 1.6 1.6 0 00-3.2 0z"></path>
								</svg>
								<b>41</b>
							</div>
						</OverlayTrigger>

						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip>1h TXs</Tooltip>}
						>
							<div className="bottom-right-item">
								<span>TXs</span>
								<b>73</b>
							</div>
						</OverlayTrigger>

						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip>1h Volume</Tooltip>}
						>
							<div className="bottom-right-item">
								<span>V</span>
								<b className="blue">$41.3K</b>
							</div>
						</OverlayTrigger>

						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip>MKT Cap</Tooltip>}
						>
							<div className="bottom-right-item">
								<span>MC</span>
								<b>$95.7K</b>
							</div>
						</OverlayTrigger>
					</div>
				</div>
			</div>
		</div>
	);
};
export default MemeCard;
