import { CustomCellRendererProps } from 'ag-grid-react';
import './cell-type.scss';
const ActivityCellType = (props: CustomCellRendererProps) => {
	return (
		<div
			className={`activity-cell-type ${
				props.value === 'Buy' ? 'buy' : props.value === 'Sell' ? 'sell' : ''
			}`}
		>
			{props.value}
		</div>
	);
};
export default ActivityCellType;
