import './index.scss';
const Audit = () => {
	return (
		<div className="audit">
			<span>Degen Audit</span>
			<a
				href="https://gopluslabs.io/token-security/solana/AqeyxvWQ5CNcbiUGWq1KKd1ZrYdr3KmTqnwWoHYGpump"
				target="_blank"
				rel="noreferrer"
			>
				<img src="https://gmgn.ai/static/img/goplus1.svg" alt="" />
				<span>GoPlus</span>
			</a>
		</div>
	);
};
export default Audit;
