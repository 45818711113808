import Web3 from 'web3';

// Sets if the example should run locally or on chain
export enum Chain {
	POLYGON,
	MAINNET,
	BSC,
	AVAX,
	SEPOLIA
}

// Inputs that configure this example to run
interface ExampleConfig {
	chain: Chain;
	rpc: {
		polygon: string;
		mainnet: string;
		bsc: string;
		avax: string;
		sepolia: string;
	};
}

// Example Configuration
export const CurrentConfig: ExampleConfig = {
	chain: Chain.SEPOLIA,
	rpc: {
		polygon: '',
		mainnet: 'https://mainnet.infura.io/v3/',
		bsc: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
		avax: 'https://long-light-pond.avalanche-testnet.quiknode.pro/35cbf62e2590b9cb8c2237bbef17157703025192/ext/bc/C/rpc/',
		sepolia:
			'https://long-light-pond.ethereum-sepolia.quiknode.pro/35cbf62e2590b9cb8c2237bbef17157703025192'
	}
};

export const EXPLORER_TYPES = {
	ADDRESS: 'address',
	TX: 'tx'
};

export const chainList: any = {
	// 43113: {
	// 	chainId: Web3.utils.toHex(43113),
	// 	rpcUrls: [
	// 		'https://long-light-pond.avalanche-testnet.quiknode.pro/35cbf62e2590b9cb8c2237bbef17157703025192/ext/bc/C/rpc/'
	// 	],
	// 	chainName: 'Avalanche',
	// 	nativeCurrency: {
	// 		name: 'Avalanche',
	// 		symbol: 'AVAX',
	// 		decimals: 18
	// 	},
	// 	blockExplorerUrls: ['https://testnet.snowtrace.io/']
	// },
	11155111: {
		chainId: Web3.utils.toHex(11155111),
		rpcUrls: [
			'https://long-light-pond.ethereum-sepolia.quiknode.pro/35cbf62e2590b9cb8c2237bbef17157703025192'
		],
		chainName: 'Sepolia',
		nativeCurrency: {
			name: 'Sepolia',
			symbol: 'ETH',
			decimals: 18
		},
		blockExplorerUrls: ['https://sepolia.etherscan.io/']
	}
};

export const ENV_CONFIGS = {
	BASE_URL: 'https://meme-app.yugi-oh.site/api/',
	EXPLORER_URL: 'https://sepolia.etherscan.io/',
	CHAIN_ID: '11155111',
	USDC_ADDRESS: '0xcC83B44ea968DaE4EC562F0E94fB37937b88db41',
	EKL_ADDRESS: '0x112127E6AfA195Ffd37a1D71e625c0400b070FBb',
	EKL_721_ADDRESS: '0x6F7a1FF0711269bd20964168247dB76e5fda9f1f',
	MARKET_CONTRACT_ADDRESS: '0x73E6093d458DbEf49dDE0889D8B4f65750078911',
	MARKET_CURRENCY_MANAGER_ADDRESS: '0x6344CC35CCD38283b10166Ab2fBdcA4933d47211',
	DEPOSIT_CONTRACT_ADDRESS: '0x89B3b8729e0e289095Ad97e24cA2c03DE44470F3',
	GAME_CONTRACT_ADDRESS: '0xC09dE4234F046157AD2857CEE356347F0736f9fC',
	// new contract
	FACTORY_MEME_CONTRACT_ADDRESS: '0x5487e491580ad1a14C5Fda25bfBeEfe56f5b5D25'
};
