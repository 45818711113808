import { useEffect, useState } from 'react';
import './index.scss';
import AutoBox from '../auto-box';
import { Accordion, FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useActiveWeb3React } from '../../../hooks';
import { useParams } from 'react-router-dom';
import {
	getAllowanceMemeToken,
	UseBuyExactIn,
	UseBuyExactOut,
	UseGetAmountInAndFee,
	UseGetAmountOutAndFee,
	UseGetVirtualCollateralReserves,
	UseGetVirtualTokenReserves,
	UseMemeTokenApproveCallback,
	UseSellExactIn,
	UseSellExactOut
} from '../../../hooks/useMemeToken';
import BigNumber from 'bignumber.js';
import { get } from 'lodash';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../../config';
import { toast } from 'react-toastify';
import { ACTION_STATUS } from '../../../constants';
import { minimizeAddress } from '../../../utils/string';
import { useTokenContract } from '../../../hooks/useTokenContract';
import {
	ETH_SEPOLIA_CHAIN_ID,
	MAP_NATIVE_COIN_NAME
} from '../../../libs/constants';
import useIsWindowVisible from '../../../hooks/useIsWindowVisible';
import useDebounce from '../../../hooks/useDebounce';
import { useOnBlockUpdated } from '../../../hooks/useOnBlockUpdated';
import { useEthBalance } from '../../../hooks/useState';
import { getFullDisplayBalance } from '../../../utils/number';
interface IBuyPanel {
	tokenDetail?: any;
}
const BuyPanel = ({ tokenDetail }: IBuyPanel) => {
	const { tokenAddress } = useParams();
	const { account, provider, chainId } = useActiveWeb3React();
	const tokenContract = useTokenContract(tokenAddress);
	const windowVisible = useIsWindowVisible();
	const ethBal = useEthBalance();

	const [tokenBal, setTokenBal] = useState<number | string>(0);
	const [currency, setCurrency] = useState('native');
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(1);
	const [amount, setAmount] = useState<number | string>('');
	const [buyType, setBuyType] = useState(1);
	const [sellType, setSellType] = useState(1);
	const [takeProfitStopLoss, setTakeProfitStopLoss] = useState(false);
	const handleBuyTypeChange = (newBuyType: number) => {
		setBuyType(newBuyType);
	};
	const handleSellTypeChange = (newSellType: number) => {
		setSellType(newSellType);
	};

	const [blockNumber, setBlockNumber] = useState(0);
	const block = useDebounce(blockNumber, 1000);

	useOnBlockUpdated((blockNumber: number) => {
		// console.log('blockNumber', blockNumber);
		setBlockNumber(blockNumber);
	});
	useEffect(() => {
		console.log('chainIdchainId', chainId);
		if (
			!chainId ||
			chainId !== ETH_SEPOLIA_CHAIN_ID ||
			!account ||
			!windowVisible ||
			!block ||
			!provider
		)
			return undefined;
		try {
			if (tokenContract) {
				tokenContract.balanceOf(account).then((data: any) => {
					console.log('token bal', data?.toString());
					setTokenBal(data?.toString());
				});
			}
		} catch (error) {
			console.log('asgajshasjas');
		}
	}, [provider, chainId, block, tokenContract, account]);

	const showSuccessToast = (hash: string) => {
		toast(
			<div>
				<span>Buy token {tokenAddress} successfully!</span>
				<button
					onClick={() => {
						window.open(
							`${ENV_CONFIGS.EXPLORER_URL}${EXPLORER_TYPES.TX}/${hash}`
						);
					}}
					style={{
						marginLeft: '10px',
						padding: '5px 10px',
						border: 'none',
						background: '#007bff',
						color: '#fff',
						borderRadius: '3px',
						cursor: 'pointer'
					}}
				>
					View on Explorer
				</button>
			</div>,
			{
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			}
		);
	};

	const handleBuyExactIn = async () => {
		setLoading(true);
		try {
			if (!account || !provider || loading || !amount) {
				alert('connect wallet');
				return;
			}
			const virtualColReserve: any = await UseGetVirtualCollateralReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualColReserve=====', virtualColReserve);
			const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualTokenReserve=====', virtualTokenReserve);
			const amoutOutAndFee: any = await UseGetAmountOutAndFee({
				amountIn: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
				reserveIn: get(virtualColReserve, '[0]', 0),
				reserveOut: get(virtualTokenReserve, '[0]', 0),
				web3Provider: provider,
				currencyAddress: tokenAddress
			});
			console.log('amoutOutAndFee======', amoutOutAndFee);
			const amountOutMin = get(amoutOutAndFee, '0', 0);
			console.log('amountOutMin===', amountOutMin);
			console.log('1111111', {
				amount: amount,
				amountOutMin,
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			UseBuyExactIn(
				{
					amount: Number(amount),
					amountOutMin,
					tokenAddress: tokenAddress,
					web3Provider: provider,
					account
				},
				async (result: any) => {
					console.log('UseBuyExactIn====', result);
					if (result.status === ACTION_STATUS.BUY_EXACT_IN_SUCCESS) {
						setLoading(false);
						setAmount('');
						showSuccessToast(result?.data);
					}
					if (result.status === ACTION_STATUS.BUY_EXACT_IN_FAIL) {
						setLoading(false);
						if (result?.message) {
							toast.error(result?.message);
						}
					}
				}
			);
		} catch (error) {
			console.log('handleBuyExactIn errorrrrr', error);
			setLoading(false);
		}
	};

	const handleBuyExactOut = async () => {
		try {
			if (!account || !provider) {
				alert('connect wallet');
				return;
			}
			setLoading(true);
			const virtualColReserve: any = await UseGetVirtualCollateralReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualColReserve=====', virtualColReserve); //
			const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualTokenReserve=====', virtualTokenReserve);
			const amoutInAndFee = await UseGetAmountInAndFee({
				amountOut: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
				reserveIn: virtualColReserve,
				reserveOut: virtualTokenReserve,
				web3Provider: provider,
				currencyAddress: tokenAddress
			});
			console.log('amoutInAndFee===', amoutInAndFee);
			const maxCollateralAmount =
				get(amoutInAndFee, '0', 0) + get(amoutInAndFee, '1', 0);
			const nativeFee = get(amoutInAndFee, '1', 0);
			console.log('nativeFee===', nativeFee);
			console.log(
				'maxCollateralAmount===',
				new BigNumber(maxCollateralAmount).toNumber()
			);

			UseBuyExactOut(
				{
					fee: nativeFee,
					tokenAddress: tokenAddress,
					tokenAmount: Number(amount),
					maxCollateralAmount: new BigNumber(maxCollateralAmount).toNumber(),
					web3Provider: provider,
					account
				},
				async (result: any) => {
					console.log('UseBuyExactOut====', result);
					if (result.status === ACTION_STATUS.BUY_EXACT_OUT_SUCCESS) {
						showSuccessToast(result?.data);
						setAmount('');
						setLoading(false);
					}
					if (result.status === ACTION_STATUS.BUY_EXACT_OUT_FAIL) {
						setLoading(false);
						if (result?.message) {
							toast.error(result?.message);
						}
					}
				}
			);
		} catch (error) {
			console.log('UseBuyExactOut errorrrrr', error);
			setLoading(false);
		}
	};

	const handleSellExactIn = async () => {
		try {
			setLoading(true);
			if (!account || !provider || !amount) {
				alert('connect wallet');
				return;
			}

			const allowance = await getAllowanceMemeToken({
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			console.log('allowanceallowanceallowance===', allowance);

			if (Number(allowance) >= Number(amount)) {
				handleSellExactInInternal(Number(amount));
			} else {
				UseMemeTokenApproveCallback(
					{
						amount: Number(amount),
						account,
						web3Provider: provider,
						tokenAddress: tokenAddress
					},
					async (result: any) => {
						console.log('111111111', result);
						if (result.status === ACTION_STATUS.APPROVED) {
							handleSellExactInInternal(Number(amount));
						}
						if (result.status === ACTION_STATUS.APPROVE_FAILS) {
							setLoading(false);
						}
					}
				);
			}
		} catch (error) {
			console.log('handleSellExactIn errorrrrr', error);
		}
	};

	const handleSellExactInInternal = async (amount: number) => {
		const virtualColReserve: any = await UseGetVirtualCollateralReserves({
			currencyAddress: tokenAddress,
			web3Provider: provider
		});
		console.log('virtualColReserve=====reserveOut===', virtualColReserve);
		const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
			currencyAddress: tokenAddress,
			web3Provider: provider
		});
		console.log('virtualTokenReserve=====reserveIn===', virtualTokenReserve);
		const amoutOutAndFee = await UseGetAmountOutAndFee({
			amountIn: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
			reserveIn: new BigNumber(virtualTokenReserve).toNumber(),
			reserveOut: new BigNumber(virtualColReserve).toNumber(),
			web3Provider: provider,
			currencyAddress: tokenAddress
		});
		console.log('amoutOutAndFee======', amoutOutAndFee);
		const amountCollateralMin =
			get(amoutOutAndFee, '0', 0) - get(amoutOutAndFee, '1', 0);
		console.log('amountCollateralMin===', amountCollateralMin);
		console.log('1111111', {
			amount: amount,
			amountCollateralMin: new BigNumber(amountCollateralMin).toNumber(),
			tokenAddress: tokenAddress,
			web3Provider: provider,
			account
		});
		UseSellExactIn(
			{
				tokenAmount: amount,
				amountCollateralMin: new BigNumber(amountCollateralMin).toNumber(),
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			},
			async (result: any) => {
				console.log('UseSellExactIn====', result);
				if (result.status === ACTION_STATUS.SELL_EXACT_IN_SUCCESS) {
					showSuccessToast(result?.data);
					setAmount('');
					setLoading(true);
				}
				if (result.status === ACTION_STATUS.SELL_EXACT_IN_FAIL) {
					setLoading(false);
					if (result?.message) {
						toast.error(result?.message);
					}
				}
			}
		);
	};

	const handleSellExactOut = async () => {
		try {
			// const amount = 0.000001;
			if (!account || !provider || !amount) {
				alert('connect wallet');
				return;
			}
			const virtualColReserve: any = await UseGetVirtualCollateralReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log(
				'virtualColReserve=====virtualColReserve===',
				virtualColReserve
			);
			const virtualTokenReserve: any = await UseGetVirtualTokenReserves({
				currencyAddress: tokenAddress,
				web3Provider: provider
			});
			console.log('virtualTokenReserve=====reserveIn===', virtualTokenReserve);
			const amountInAndFee: any = await UseGetAmountInAndFee({
				amountOut: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
				reserveIn: new BigNumber(virtualTokenReserve).toNumber(),
				reserveOut: new BigNumber(virtualColReserve).toNumber(),
				payment: true,
				web3Provider: provider,
				currencyAddress: tokenAddress
			});
			console.log('amountInAndFee======', amountInAndFee);
			const tokenAmountMax = get(amountInAndFee, '0', 0);
			const allowance = await getAllowanceMemeToken({
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			console.log('allowancea2222llowanceallowance===', allowance);

			if (Number(allowance) >= tokenAmountMax) {
				handleSellExactOutInternal(Number(amount), tokenAmountMax);
			} else {
				UseMemeTokenApproveCallback(
					{
						amount: tokenAmountMax,
						account,
						web3Provider: provider,
						tokenAddress: tokenAddress
					},
					async (result: any) => {
						console.log('111111111', result);
						if (result.status === ACTION_STATUS.APPROVED) {
							handleSellExactOutInternal(Number(amount), tokenAmountMax);
						}
						if (result.status === ACTION_STATUS.APPROVE_FAILS) {
						}
					}
				);
			}
		} catch (error) {
			console.log('handleSellExactOut errorrrrr', error);
		}
	};

	const handleSellExactOutInternal = async (
		amount: number,
		tokenAmountMax: number
	) => {
		try {
			console.log('1111111', {
				amount: new BigNumber(amount).multipliedBy(10 ** 18).toNumber(),
				tokenAmountMax: new BigNumber(tokenAmountMax).toNumber(),
				tokenAddress: tokenAddress,
				web3Provider: provider,
				account
			});
			UseSellExactOut(
				{
					tokenAmountMax: new BigNumber(tokenAmountMax).toNumber(),
					amountCollateral: amount,
					tokenAddress: tokenAddress,
					web3Provider: provider,
					account
				},
				async (result: any) => {
					console.log('UseSellExactOut====', result);
					if (result.status === ACTION_STATUS.SELL_EXACT_OUT_SUCCESS) {
						showSuccessToast(result?.data);
					}
					if (result.status === ACTION_STATUS.SELL_EXACT_OUT_FAIL) {
					}
				}
			);
		} catch (error) {
			console.log('handleSellExactOut errorrrrr', error);
		}
	};

	const handleChangeText = (e: any) => {
		setAmount(e.target.value);
	};

	return (
		<div className="buy-panel">
			{/* Header */}
			<div className="buy-panel-header">
				<div className="buy-panel-address">
					<div className="avatar">
						<img src="/logo.png" alt="" />
					</div>
					<div className="address">
						{account ? minimizeAddress(account) : '---'}
					</div>
					<div className="balance">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="12px"
							height="12px"
							fill="currentColor"
							viewBox="0 0 12 12"
						>
							<path d="M1.7 8.197c.063-.063.15-.1.242-.1h8.35c.152 0 .229.184.12.292l-1.649 1.65a.342.342 0 01-.242.1H.171a.171.171 0 01-.12-.293L1.7 8.196z"></path>
							<path d="M1.7 2.04a.352.352 0 01.242-.1h8.35c.152 0 .229.184.12.292l-1.649 1.65a.342.342 0 01-.242.1H.171a.171.171 0 01-.12-.293L1.7 2.04z"></path>
							<path d="M8.763 5.1a.342.342 0 00-.242-.1H.171a.171.171 0 00-.12.293L1.7 6.942c.063.063.15.1.242.1h8.35a.171.171 0 00.12-.292L8.764 5.1z"></path>
						</svg>
						<span>{getFullDisplayBalance(ethBal)}</span>
					</div>
				</div>
				<div className="buy-panel-add">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20px"
						height="20px"
						fill="#F5F5F5"
						viewBox="0 0 16 16"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M8 2a1 1 0 011 1v4h4a1 1 0 110 2H9v4a1 1 0 11-2 0V9H3a1 1 0 010-2h4V3a1 1 0 011-1z"
						></path>
					</svg>
				</div>
			</div>

			{/* Tabs */}
			<div className="buy-panel-tabs">
				{/* Buy */}
				<button
					type="button"
					className={`buy ${selectedTab === 1 ? 'active' : ''}`}
					onClick={() => {
						setAmount('');
						setSelectedTab(1);
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12px"
						height="12px"
						fill="currentColor"
						viewBox="0 0 20 20"
					>
						<path d="M10.408 18.657l6.378-9.566a.818.818 0 00-.68-1.272H11.09V1.796a.818.818 0 00-1.499-.454L3.214 10.91a.818.818 0 00.68 1.272H8.91v6.023a.818.818 0 001.498.453z"></path>
					</svg>
					<span>Buy</span>
				</button>

				{/* Sell */}
				<button
					type="button"
					className={`sell ${selectedTab === 2 ? 'active' : ''}`}
					onClick={() => {
						setAmount('');
						setSelectedTab(2);
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12px"
						height="12px"
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<g clipPath="url(#clip0_5354_11962)">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.131 7.134a1.6 1.6 0 000 2.263l5.657 5.657a1.6 1.6 0 002.263 0l6.058-6.058c.3-.3.468-.707.468-1.131V2.208a1.6 1.6 0 00-1.6-1.6H8.32a1.6 1.6 0 00-1.131.469L1.131 7.134zm10.069-.73a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2z"
							></path>
						</g>
						<defs>
							<clipPath id="clip0_5354_11962">
								<rect width="16" height="16"></rect>
							</clipPath>
						</defs>
					</svg>
					<span>Sell</span>
				</button>

				{/* Auto */}
				<button
					type="button"
					className={`auto ${selectedTab === 3 ? 'active' : ''}`}
					onClick={() => setSelectedTab(3)}
				>
					<svg width="100%" viewBox="0 0 28.135986328125 17">
						<defs>
							<linearGradient
								id="gridienta1c6e18559513eff"
								x1="0"
								y1="0"
								x2="100%"
								y2="0"
							>
								<stop offset="26.87%" stopColor="#88D693"></stop>
								<stop offset="64.85%" stopColor="#1CC9FF"></stop>
							</linearGradient>
						</defs>
						<text
							x="50%"
							y="50%"
							dy="0.3em"
							textAnchor="middle"
							fill="url(#gridienta1c6e18559513eff)"
							fontSize="12"
							fontWeight="400"
						>
							<tspan>Auto</tspan>
						</text>
					</svg>
				</button>
			</div>

			{/* Body */}
			<div className="buy-panel-body">
				{/* Buy tab */}
				{selectedTab === 1 ? (
					<div className="buy-panel-tab-panel buy">
						{/* Buy type tabs */}
						<div className="tabs">
							<button
								type="button"
								className={buyType === 1 ? 'active' : ''}
								onClick={() => handleBuyTypeChange(1)}
							>
								Buy Now
							</button>
							<button
								type="button"
								className={buyType === 2 ? 'active' : ''}
								onClick={() => handleBuyTypeChange(2)}
							>
								Buy Dip
							</button>
							<span>
								Bal: {getFullDisplayBalance(ethBal)}{' '}
								{MAP_NATIVE_COIN_NAME[chainId]}
							</span>
						</div>

						{/* Buy form */}
						{buyType === 1 ? (
							<div className="buy-sell-form">
								<div className="input-wrap">
									<div className="input-amount">
										<span>Amount</span>
										<input
											value={amount}
											onChange={handleChangeText}
											type="number"
										/>
										<span>
											<select
												value={currency}
												onChange={(e: any) => {
													setCurrency(e.target.value);
													setAmount('');
												}}
												name="currency"
												id="currency"
												className="ml-1"
											>
												<option value={'native'}>
													{MAP_NATIVE_COIN_NAME[chainId]}
												</option>
												<option value="token">
													{get(tokenDetail, 'symbol', '')}
												</option>
											</select>
										</span>
									</div>

									<div className="quick-input">
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="tooltip-1">
													0.01 {MAP_NATIVE_COIN_NAME[chainId]}
												</Tooltip>
											}
										>
											<button onClick={() => setAmount(0.01)} type="button">
												0.01
											</button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="tooltip-2">
													0.1 {MAP_NATIVE_COIN_NAME[chainId]}
												</Tooltip>
											}
										>
											<button onClick={() => setAmount(0.1)} type="button">
												0.1
											</button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="tooltip-3">
													0.5 {MAP_NATIVE_COIN_NAME[chainId]}
												</Tooltip>
											}
										>
											<button onClick={() => setAmount(0.5)} type="button">
												0.5
											</button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="tooltip-4">
													1 {MAP_NATIVE_COIN_NAME[chainId]}
												</Tooltip>
											}
										>
											<button onClick={() => setAmount(1)} type="button">
												1
											</button>
										</OverlayTrigger>
									</div>
								</div>

								<div className="take-profit-stop-loss">
									<OverlayTrigger
										placement="left"
										overlay={
											<Tooltip id="tooltip-take-profit-stop-loss">
												<p>
													<b>Take Profit:</b>
													<br />
													Automatically triggers a sell when the price reaches
													the take-profit price.
													<br />
													Take-profit price = current price + current price *
													take-profit percentage
												</p>

												<p>
													<b>Stop Loss:</b>
													<br />
													Automatically triggers a sell when the price falls to
													the stop-loss price.
													<br />
													Stop-loss price = current price - current price *
													stop-loss percentage
												</p>
											</Tooltip>
										}
									>
										<div>
											<FormCheck
												type="checkbox"
												id="chk-1"
												label="Take Profit & Stop Loss"
												checked={takeProfitStopLoss}
												onChange={e => setTakeProfitStopLoss(e.target.checked)}
											/>
										</div>
									</OverlayTrigger>
									{takeProfitStopLoss ? (
										<AutoBox>
											To use the <strong>Take Profit & Stop Loss</strong>
											<br />
											link your TGBot Wallet
										</AutoBox>
									) : (
										<></>
									)}
								</div>

								<button
									onClick={() => {
										if (currency === 'native') {
											handleBuyExactIn();
										} else {
											handleBuyExactOut();
										}
									}}
									disabled={
										!amount ||
										Number(amount) <= 0 ||
										loading ||
										!account ||
										(currency === 'native' &&
											new BigNumber(ethBal).lte(
												BigNumber(amount).multipliedBy(10 ** 18)
											))
									}
									type="button"
									className="btn btn-warning w-100 btn-sm"
								>
									Buy
								</button>

								<Accordion defaultActiveKey="-1">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<span>
												Slippage:{' '}
												<u>
													Auto(<span className="text-warning">30%</span>)
												</u>
											</span>
											<span>
												PRO: <u>0.012</u>
											</span>
											<span>
												MEV: <u>OFF</u>
											</span>
										</Accordion.Header>
										<Accordion.Body>
											<div className="item">
												<div className="item-label">Slippage</div>
												<button type="button" className="active">
													Auto 30.0%
												</button>
												<div className="input-field">
													<input type="number" />
													<span>%</span>
												</div>
											</div>

											<div className="item">
												<div className="item-label">Priority Fee (SOL)</div>
												<button type="button" className="active">
													x10 0.012
												</button>
												<input type="number" placeholder="Custom" />
											</div>

											<div className="item">
												<div className="item-label">Boost mode(Anti-MEV)</div>
												<FormCheck type="switch" id="custom-switch" />
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						) : (
							<AutoBox>
								To use the <strong>Buy Dip&Auto Sell</strong>
								<br />
								link your TGBot Wallet for
								<br />
								automaticorder placement.
							</AutoBox>
						)}
					</div>
				) : (
					<></>
				)}

				{/* Sell tab */}
				{selectedTab === 2 ? (
					<div className="buy-panel-tab-panel sell">
						{/* Sell type tabs */}
						<div className="tabs">
							<button
								type="button"
								className={sellType === 1 ? 'active' : ''}
								onClick={() => handleSellTypeChange(1)}
							>
								Sell Now
							</button>
							<button
								type="button"
								className={sellType === 2 ? 'active' : ''}
								onClick={() => handleSellTypeChange(2)}
							>
								Auto Sell
							</button>

							<span>
								Bal: {getFullDisplayBalance(tokenBal)}{' '}
								{get(tokenDetail, 'symbol', '')}
							</span>
						</div>

						{/* Sell form */}
						{sellType === 1 ? (
							<div className="buy-sell-form">
								<div className="input-wrap">
									<div className="input-amount">
										<span>Amount</span>
										<input
											value={amount}
											onChange={handleChangeText}
											type="number"
										/>
										<span>{get(tokenDetail, 'symbol', '')}</span>
										{/* <span>
											<select
												value={currency}
												onChange={(e: any) => {
													setCurrency(e.target.value);
													setAmount('');
												}}
												name="currency"
												id="currency"
												className="ml-1"
											>
												<option value={'native'}>
													{MAP_NATIVE_COIN_NAME[chainId]}
												</option>
												<option value="token">
													{get(tokenDetail, 'symbol', '')}
												</option>
											</select>
										</span> */}
									</div>

									<div className="quick-input">
										<OverlayTrigger
											placement="bottom"
											overlay={<Tooltip id="tooltip-5">25 %</Tooltip>}
										>
											<button
												onClick={() => {
													setAmount(
														getFullDisplayBalance(
															new BigNumber(tokenBal)
																.multipliedBy(0.25)
																.toString()
														)
													);
												}}
												type="button"
											>
												25%
											</button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={<Tooltip id="tooltip-6">50 %</Tooltip>}
										>
											<button
												onClick={() => {
													setAmount(
														getFullDisplayBalance(
															new BigNumber(tokenBal)
																.multipliedBy(0.5)
																.toString()
														)
													);
												}}
												type="button"
											>
												50%
											</button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={<Tooltip id="tooltip-7">75 %</Tooltip>}
										>
											<button
												onClick={() => {
													setAmount(
														getFullDisplayBalance(
															new BigNumber(tokenBal)
																.multipliedBy(0.75)
																.toString()
														)
													);
												}}
												type="button"
											>
												75%
											</button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={<Tooltip id="tooltip-8">100 %</Tooltip>}
										>
											<button
												onClick={() => {
													setAmount(
														getFullDisplayBalance(
															new BigNumber(tokenBal).toString()
														)
													);
												}}
												type="button"
											>
												100%
											</button>
										</OverlayTrigger>
									</div>
								</div>

								<button
									disabled={
										!amount ||
										Number(amount) <= 0 ||
										loading ||
										!account ||
										(currency === 'token' &&
											new BigNumber(tokenBal).lte(
												BigNumber(amount).multipliedBy(10 ** 18)
											)) ||
										(currency === 'native' &&
											new BigNumber(tokenBal).lte(
												BigNumber(amount).multipliedBy(10 ** 18)
											))
									}
									onClick={() => {
										// if (currency === 'native') {
										// 	handleSellExactIn();
										// } else {
										// 	handleSellExactOut();
										// }
										handleSellExactIn();
									}}
									type="button"
									className="btn btn-warning w-100 btn-sm"
								>
									Sell
								</button>

								<Accordion defaultActiveKey="-1">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<span>
												Slippage:{' '}
												<u>
													Auto(<span className="text-warning">30%</span>)
												</u>
											</span>
											<span>
												PRO: <u>0.012</u>
											</span>
											<span>
												MEV: <u>OFF</u>
											</span>
										</Accordion.Header>
										<Accordion.Body>
											<div className="item">
												<div className="item-label">Slippage</div>
												<button type="button" className="active">
													Auto 30.0%
												</button>
												<div className="input-field">
													<input type="number" />
													<span>%</span>
												</div>
											</div>

											<div className="item">
												<div className="item-label">Priority Fee (SOL)</div>
												<button type="button" className="active">
													x10 0.012
												</button>
												<input type="number" placeholder="Custom" />
											</div>

											<div className="item">
												<div className="item-label">Boost mode(Anti-MEV)</div>
												<FormCheck type="switch" id="custom-switch" />
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						) : (
							<AutoBox>
								To use the <strong>Buy Dip&Auto Sell</strong>
								<br />
								link your TGBot Wallet for
								<br />
								automaticorder placement.
							</AutoBox>
						)}
					</div>
				) : (
					<></>
				)}

				{/* Auto tab */}
				{selectedTab === 3 ? (
					<div className="buy-panel-tab-panel auto">
						<AutoBox>
							To use the <strong>Auto Trading</strong> <br />
							link your TGBot Wallet
						</AutoBox>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
export default BuyPanel;
